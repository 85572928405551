import { Box, Button, CircularProgress, Container, Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { getRegisterOffice, verifyProviderId } from "../../helper/API/auth";
import { authentication, provider } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup } from "firebase/auth";
import * as registerAction from "../../redux/Register/action";
import * as loginAction from "../../redux/login/action";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CLIENT_ID, CONTRY_CODE } from "../../config";
import { gapi } from "gapi-script";

const Register = ({ userInfo, loginData, loginSocialAuth, loginLocalData }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    telephone: "",
  });

  const [prefile, setPrefile] = useState([]);

  // console.log("prefile", prefile);

  const [selectData, setSelectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [occupationType, setOccupationType] = useState(null);
  const [hide, setHide] = useState(false);
  // console.log("occupationType", occupationType);
  const [refresh, setRefresh] = useState(0);
  const [socialRes, setSocialRes] = useState({
    providerId: "",
    provider: "",
  });

  useEffect(() => {
    getRegisterOffice().then(res => {
      // console.log("res", res);
      if (res.success) {
        setSelectData(res?.data);
      }
    });
  }, []);
  // console.log("selectData", selectData);
  const clientId = "680552504511-1k98moqnnir7lbkf25ck36pu0a11ip1g.apps.googleusercontent.com";
  // console.log("loginProcessing", loginProcessing);
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = resp => {
    const submitData = { providerId: resp.googleId };
    // console.log("resp", resp);
    setHide(true);
    setPrefile(resp);
    setFormValues({
      ...formValues,
      email: resp.profileObj.email,
      name: resp.profileObj.name,
    });
    if (resp.googleId) {
      verifyProviderId(submitData).then(res => {
        // console.log("res", res);
        // console.log("resp.googleId", resp.googleId);
        if (res.success) {
          setHide(true);
          loginData(res.data);
          loginLocalData({ email: resp.profileObj.email, password: resp.profileObj.name });
          // loginSocialAuth(res);
          toast.success(res.message);
          // userInfo({ formValues, occupationType, providerId: resp.googleId, provider: "google" });
          setSocialRes({ providerId: resp.googleId, provider: "google" });
        } else {
          toast.error("E-mail não cadastrado.");
          setSocialRes({ providerId: resp.googleId, provider: "google" });
        }
      });
    } else {
      // console.log("failed:", err);
    }
  };
  const onFailure = err => {
    setHide(false);
    // console.log("failed:", err);
    toast.error(err);
  };

  // FACEBOOK LOGIN
  const handleFacebookLogin = e => {
    e.preventDefault();
    signInWithPopup(authentication, provider)
      .then(resp => {
        // console.log("res facebook login", resp);

        if (resp.user.uid) {
          const submitData = { providerId: resp.user.uid };
          setHide(true);
          setPrefile(resp);
          setFormValues({
            ...formValues,
            email: resp.user.email,
            name: resp.user.displayName,
          });
          setSocialRes({ providerId: resp.user.uid, provider: "facebook" });
          verifyProviderId(submitData).then(res => {
            // console.log("res", res);
            if (res.success) {
              setHide(true);
              loginData(res.data);
              loginLocalData({ email: resp.user.email, password: resp.user.name });
              // loginSocialAuth(res);
              toast.success(res.message);
              // userInfo({ formValues, occupationType, providerId: resp.user.uid, provider: "facebook" });
              setSocialRes({ providerId: resp.user.uid, provider: "facebook" });
            } else {
              toast.error("E-mail não cadastrado.");
              setSocialRes({ providerId: resp.user.uid, provider: "facebook" });
            }
          });
        } else {
          toast.error("E-mail não cadastrado");
        }
      })
      .catch(error => {
        // Error; SMS not sent
        // console.log("error facebook login", error);
      });
  };

  // APPLE LOGIN

  const onSuccessApple = (provider, data) => {
    const submitData = { providerId: provider.data.authorization.id_token };
    // console.log("resp", resp);
    setHide(true);
    setPrefile(provider);
    // setFormValues({
    //   ...formValues,
    //   email: resp.profileObj.email,
    //   name: resp.profileObj.name,
    // });
    if (provider.data.authorization.id_token) {
      verifyProviderId(submitData).then(res => {
        // console.log("res", res);
        if (res.success) {
          setHide(true);
          loginData(res.data);
          loginLocalData({ email: "", password: "" });
          // loginSocialAuth(res);
          toast.success(res.message);
          // userInfo({ formValues, occupationType, providerId: resp.googleId, provider: "google" });
          setSocialRes({ providerId: provider.data.authorization.id_token, provider: "apple" });
        } else {
          toast.error("E-mail não cadastrado.");
          setSocialRes({ providerId: provider.data.authorization.id_token, provider: "apple" });
        }
      });
    } else {
      // console.log("failed:", err);
    }
  };

  // console.log("socialRes", socialRes);

  const Img = styled("img")({
    // margin: 'auto',
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  const navigate = useNavigate();

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = e => {
    setOccupationType(e.target.value);
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: response => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      authentication,
    );
  };
  const handleClick = () => {
    setLoading(true);
    if (!formValues.name) {
      toast.error("Por favor, insira o nome");
      setLoading(false);
    } else if (!formValues.email) {
      toast.error("Por favor, insira o e-mail");
      setLoading(false);
    } else if (!hide && !formValues.password) {
      toast.error("Por favor, digite a senha");
      setLoading(false);
    } else if (!formValues.telephone) {
      toast.error("Por favor insira o telefone");
      setLoading(false);
    } else if (!occupationType) {
      toast.error("Por favor, insira o tipo de escritório");
      setLoading(false);
    } else {
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      let phoneNumber = `${CONTRY_CODE} ${Number(formValues.telephone)}`;
      // console.log("phoneNumber", phoneNumber);
      signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then(confirmationResult => {
          window.confirmationResult = confirmationResult;
          userInfo({ formValues, occupationType, socialRes });
          navigate("/otp");
          setLoading(false);
        })
        .catch(error => {
          // Error; SMS not sent
          // console.log("error", error.message);
          setLoading(false);
          toast.error(error.message);
        });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="md" className="registerContainer">
        <Box width={"100%"}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Img alt="complex" src="/assets/images/inova_juris_logo.png" sx={{ height: 150 }} />
          </Box>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  placeholder="Nome"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  name="name"
                  onChange={handleChange}
                  value={formValues.name}
                  type="text"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  placeholder="Digite seu E-mail "
                  required
                  fullWidth
                  id="email"
                  label="Endereço de E-mail"
                  name="email"
                  onChange={handleChange}
                  value={formValues.email}
                  type="email"
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, md: 2 }}>
              {!hide ? (
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    placeholder="Digite sua senha"
                    required
                    fullWidth
                    name="password"
                    onChange={handleChange}
                    value={formValues.password}
                    label="Senha"
                    type="password"
                    id="password"
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  placeholder="Telefone"
                  required
                  fullWidth
                  name="telephone"
                  onChange={handleChange}
                  value={formValues.telephone}
                  label="Telefone"
                  type="text"
                  id="telephone"
                />
              </Grid>

              <Grid item xs={12} md={hide && 6}>
                <TextField
                  // id="standard-select-currency"
                  margin="normal"
                  select
                  name="userType"
                  variant="outlined"
                  fullWidth
                  label="Tipo de pessoa Física ou Juridica"
                  onChange={handleSelectChange}>
                  {/* {getOfficeData && <MenuItem value={occupationId}>{selectVal}</MenuItem>} */}
                  {selectData?.map((obj, indx) => (
                    <MenuItem value={obj.id} key={indx}>
                      {obj.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "end", marginBottom: "0.5rem" }}>
              <Grid item mt={2}>
                <RouterLink to="/">
                  <Link variant="body2" sx={{ color: "#9A9AB0", textDecoration: "none !important" }}>
                    Já tem uma conta? Conecte-se
                  </Link>
                </RouterLink>
              </Grid>
            </Grid>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item md={6}>
                <Button
                  type={"button"}
                  variant="contained"
                  onClick={handleClick}
                  fullWidth
                  sx={{ mt: 3, mb: 2, backgroundColor: "#734699" }}
                  disabled={loading}>
                  Entrar
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
            <div id="recaptcha-container"></div>
          </Box>
        </Box>
      </Container >
    </>
  );
};

const mapDispatchToProp = dispatch => {
  return {
    userInfo: data => dispatch(registerAction.setRegisterInfo(data)),
    loginLocalData: data => dispatch(loginAction.setData(data)),
    loginData: data => dispatch(loginAction.setLoginUser(data)),
  };
};

export default connect(null, mapDispatchToProp)(Register);
