import React from "react";
import { Avatar, AvatarGroup, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import PlusBottom from "../PlusBottom";
import EditIcon from "@mui/icons-material/Edit";
const Img = styled("img")({
  display: "block",
});

const TarefaCard = ({ style, txtColor, timeColor, avatar, lastTxt, handleClickOpen, data, handleClick, EditProcessTask, permission }) => {
  const ab = data?.inviteMembers?.map((obj, index) => console.log("obj", obj));

  return (
    <div>
      <div style={style}>
        {permission?.isOwner && <PlusBottom url="/information" handleClick={() => handleClick(data)} />}
        <Grid container sx={{ padding: "0.5rem" }}>
          <Grid item md={3}>
            <Typography sx={{ color: txtColor, fontSize: "20px", fontFamily: "Poppins", fontWeight: 500 }}>{data?.title}</Typography>
            <Typography sx={{ color: timeColor, fontSize: "16px", fontFamily: "Poppins", fontWeight: 500 }}>
              {data?.startTime} - {data?.endTime}
            </Typography>
          </Grid>
          <Grid item md={3} sx={{ fontFamily: "Poppins", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <AvatarGroup max={4}>
              {data?.inviteMembers?.map((obj, index) => (
                <Avatar src={obj?.avatar} key={index} />
              ))}
              {/* <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
            </AvatarGroup>
          </Grid>
          <Grid item md={3} sx={{ color: "#D2A4A8", fontFamily: "Poppins", display: "flex", justifyContent: "end", alignItems: "center" }}>
            {/* UNHIDE FOR EDIT WORKING */}
            {/* <EditIcon
              width={50}
              height={50}
              sx={{
                maxWidth: "20px !important",
                maxHeight: "20px !important",
                posotion: "absolute",
                background: "#fff",
                color: "#734699",
                padding: "12px",
                borderRadius: "11px",
                cursor: "pointer",
                mr: 2,
              }}
              onClick={() => EditProcessTask(data)}
            /> */}
            <Img
              alt="Delete"
              src="/assets/images/registerDelete.png"
              width={50}
              height={50}
              sx={{
                maxWidth: "20px !important",
                maxHeight: "20px !important",
                posotion: "absolute",
                background: "#fff",
                // color: "red",
                padding: "12px",
                borderRadius: "11px",
                cursor: "pointer",
              }}
              onClick={() => handleClickOpen(data?.id)}
            />
          </Grid>
          <Grid
            item
            md={3}
            sx={{ color: timeColor, fontWeight: 500, fontFamily: "Poppins", display: "flex", justifyContent: "end", alignItems: "center" }}>
            {data.time}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TarefaCard;
