import * as React from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";
import CustomProgressBar from "../components/Inputs/ProgressBar";

const Img = styled("img")({
  display: "block",
  maxWidth: "90px",
  // maxHeight: "50px",
});
const useStyles = makeStyles({
  mainContainer: {
    display: "flex !important",
  },
  relative: {
    position: "relative",
  },
  card: {
    backgroundColor: "#2E1E42 !important",
    borderRadius: "17.4px !important",
    minHeight: 150,
    position: "relative",
  },

  title: {
    color: "#fff",
    margin: "0 !important",
    fontSize: 26,
    fontWeight: 700,
    // fontFamily: "Robot",
    letterSpacing: 0.25,
    padding: 0,
  },
  amount: {
    color: "#6B7488",
    fontSize: 16,
    margin: "0.9% 0 !important",
    letterSpacing: "1.5px",
  },
  perentage: {
    color: "#FFAA72",
    fontSize: 16,
    margin: "0 !important",
    letterSpacing: "1.5px",
  },
  month: {
    color: "#6B7488",
    fontSize: 16,
    letterSpacing: "1.5px",
    display: "table-cell",
    verticalAlign: "bottom",
    padding: "10px",
    margin: 0,
  },
});

const ProgressCard = ({ dashboardGoals }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const financialData = {
    lightColor: "##465063",
    darkColor: "linear-gradient(180deg, #FFD4AD 0%, #FF7629 125%)",
    value: dashboardGoals?.totalEarning?.per >= 100 ? 100 : dashboardGoals?.totalEarning?.per,
    width: "95%",
  };
  const targerClientsData = {
    lightColor: "##465063",
    darkColor: "linear-gradient(180deg, #FFD4AD 0%, #FF7629 125%)",
    value: dashboardGoals?.clients?.per >= 100 ? 100 : dashboardGoals?.clients?.per,
    width: "95%",
  };

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  // console.log("dashboardGoals", dashboardGoals);
  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }} px={8} py={4}>
        <Grid container columns={{ md: 12 }}>
          <Grid item md={12}>
            <Card variant="elevation" className={classes.card}>
              <CardContent>
                <Box p={1} display={"flex"} justifyContent={"space-between"}>
                  <Box p={1}>
                    <Img src="/assets/images/financeGoal.jpg" alt="gráfico ícones" sx={{ borderRadius: "10px" }} />
                  </Box>
                  <Box flex={1} p={1}>
                    <p className={classes.title} p={0}>
                      {t("financialGoal")}
                    </p>
                    <p className={classes.amount}>
                      {convertBrazilNumber(dashboardGoals?.totalEarning?.have ? dashboardGoals?.totalEarning?.have : 0)} -{" "}
                      {convertBrazilNumber(dashboardGoals?.totalEarning?.goal ? dashboardGoals?.totalEarning?.goal : 0)}
                    </p>
                    <Box flex={1} p={0}>
                      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <CustomProgressBar data={financialData} />
                        <p className={classes.perentage}>{dashboardGoals?.totalEarning?.per ? dashboardGoals?.totalEarning?.per : 0}%</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box p={0} display={"table"}>
                    {/* <p className={classes.month}>April</p> */}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} mt={2}>
            <Card variant="elevation" className={classes.card}>
              <CardContent>
                <Box p={1} display={"flex"} justifyContent={"space-between"}>
                  <Box p={1}>
                    <Img alt="complex" src="/assets/images/clientGoal.jpg" sx={{ borderRadius: "10px" }} />
                  </Box>
                  <Box flex={1} p={1}>
                    <p className={classes.title} p={0}>
                      {t("targetClients")}
                    </p>
                    <p className={classes.amount}>
                      {numberWithCommas(dashboardGoals?.clients?.have)} - {numberWithCommas(dashboardGoals?.clients?.goal)}{" "}
                    </p>
                    <Box flex={1} p={0}>
                      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <CustomProgressBar data={targerClientsData} />
                        <p className={classes.perentage}>{dashboardGoals?.clients?.per}%</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box p={0} display={"table"}>
                    {/* <p className={classes.month}>April</p> */}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProgressCard;
