import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./i18n";
import AuthProvider from "./hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Adicionar os imports do GTM e Facebook Pixel
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

// Configurações do tema
const theme = createTheme({
  palette: {
    primary: { main: "#734699" },
  },
});

// Inicializar o GTM
const tagManagerArgs = {
  gtmId: "GTM-KNL95LD", // Substitua pelo seu ID do GTM
};
TagManager.initialize(tagManagerArgs);

// Inicializar o Facebook Pixel
const advancedMatching = {}; // Caso tenha dados avançados, adicione aqui
const options = {
  autoConfig: true, // Habilita a configuração automática
  debug: false, // Ative debug se precisar ver logs no console
};
ReactPixel.init("503931255858617", advancedMatching, options);
ReactPixel.pageView(); // Para registrar a visualização da página

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />
            <ToastContainer position="top-center" />
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
