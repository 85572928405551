import * as React from "react";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import RecordFound from "../RecordFound/RecordFound";

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  marginRight: 4,
});
const useStyles = makeStyles({
  root: {
    "&:nth-child(2)": {},
  },
  card: {
    minHeight: 150,
    boxShadow: "none !important",
    borderRadius: "19.18px !important",
    fontSize: "16.73px",
  },
  viewMore: {
    fontSize: 19,
    fontWeight: 500,
    margin: "0px !important",
    overflowWrap: "break-word",
  },
  time: {
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.25px",
    lineHeight: "30px",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    overflowWrap: "break-word",
    margin: "0px !important",
  },
  dotIcon: {
    fontSize: "6px !important",
    marginRight: 3,
  },
  mainTitle: {
    fontSize: 25,
    fontWeight: 700,
    margin: "1%",
  },
});

const CustomCard = ({ taskList }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // console.log("taskList", taskList);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} px={8} py={4}>
        <div className={classes.mainTitle}>Alertas </div>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className={classes.root}>
          {taskList.length ? (
            taskList?.map((obj, index) => (
              <Grid item xs={2} sm={4} md={3} key={index}>
                <Card
                  variant="elevation"
                  className={classes.card}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#DBD3E2" : "#2E1E42",
                    color: index % 2 === 0 ? "#2E1E42" : "#FFFFFF",
                  }}>
                  <CardContent className={classes.cardContent}>
                    <Box>
                      <Box
                        p={1}
                        display={"flex"}
                        justifyContent={"space-between"}
                        sx={{ borderLeft: index % 2 === 0 ? "2px solid #FEDD71" : "2px solid #A6F19F" }}>
                        {/*   <Divider orientation="vertical" flexItem></Divider>*/}
                        <h3 className={classes.title}>{obj.title}</h3>
                        <Box>
                          <Box display={"flex"} justifyContent={"space-between"}>
                            <Img
                              alt="complex"
                              src={index % 2 === 0 ? "/assets/images/calender_colored.svg" : "/assets/images/calender_white.svg"}
                            />
                            <p className={classes.time}>{obj?.date.substring(0, obj?.date.lastIndexOf("/"))}</p>
                          </Box>
                        </Box>
                      </Box>
                      <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box>
                          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <CircleIcon className={classes.dotIcon} sx={{ color: index % 2 === 0 ? "#2E1E42" : "#FFFFFF" }} />
                            <p className={classes.time}>{obj.startTime}</p>
                          </Box>
                        </Box>
                        <Link
                          to={`/home/criar-tarefa/${obj.id}`}
                          // style={{ textDecoration: "none" }}
                          state={{ task: obj }}
                          style={{ color: index % 2 === 0 ? "#2E1E42" : "#FFFFFF", textDecoration: "none" }}>
                          <p className={classes.viewMore}>{t("Ver mais")}</p>
                        </Link>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={12} textAlign={"center"}>
              <RecordFound label={"Nenhum alerta encontrado"} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CustomCard;
