import React, { useEffect, useState } from "react";
import PlanCards from "../components/PlanCards";
import { Grid } from "@mui/material";
import Payment from "../components/PlanCards/Payment";
import { getPlans } from "../helper/API/auth";

const Plan = () => {
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState([]);
  const [planObjId, setPlanObjId] = useState([]);
  // console.log("plan", plan);

  useEffect(() => {
    getPlans().then(res => {
      // console.log("plans response", res);
      if (res.success) {
        setPlan(res.data.slice(0, 3));
      } else {
        setPlan([]);
      }
    });
  }, []);

  const handleClickOpen = id => {
    setPlanObjId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };

  const getPrices = (index) => {
    if (index === 0) {
      return {
        new: 200
      }
    } else if (index === 1) {
      return {
        new: 400
      }
    } else {
      return {
        new: 1000
      }
    }
  }


  return (
    <>
      <PlanCards>
        {plan &&
          plan.map((obj, index) => (
            <Grid item xs={12} sm={8} md={4} lg={3}>
              <div className="" style={{ margin: "1rem" }}>
                <div
                  className="wp-block-column box is-layout-flow"
                  style={{
                    flexBasis: "24%",
                  }}>
                  <p
                    className="has-text-align-center styled-title"
                    style={{
                      fontSize: "12px",
                    }}>
                    <strong>
                      {obj?.name}
                      <br />
                      {obj.subtitle}
                    </strong>
                  </p>

                  <p className="has-text-align-center styled-title has-medium-font-size">
                    <strong>{convertBrazilNumber(obj?.priceForMonth)} / mês</strong>
                  </p>

                  {/* <p className="has-text-align-center has-small-font-size">
                    Monitoramento de até 50
                    {obj?.noOfProcess >= 10 && <s>{obj?.noOfProcess - 10} </s>}
                    {obj?.noOfProcess} processos (promoção de lançamento)
                  </p> */}
                  <p className="has-text-align-center has-small-font-size">
                    Monitoramento de até {getPrices(index).new} processos (promoção de lançamento)</p>

                  <p className="has-text-align-center has-small-font-size">Gestão financeira (despesas e honorários)</p>
                  <p className="has-text-align-center has-small-font-size">Gestão de clientes – cadastro e acesso rápido aos clientes</p>
                  <p className="has-text-align-center has-small-font-size">Comunicação efetiva entre advogado e cliente – Chat</p>
                  <p className="has-text-align-center has-small-font-size">Gestão de processos – andamento processual automático</p>
                  <p className="has-text-align-center has-small-font-size">Acesso exclusivo para clientes</p>

                  {index > 0 ? <p className="has-text-align-center has-small-font-size">Gestão de equipe do escritório</p> : null}



                  <div style={{ height: "30px" }} aria-hidden="true" className="wp-block-spacer"></div>

                  <div
                    className="wp-block-buttons is-content-justification-center is-layout-flex"
                    style={{ display: "flex", alignItems: "center" }}>
                    <div className="wp-block-button wide-button-small">
                      <button
                        className="wp-block-button__link has-background"
                        // href="https://app.inovajuris.com.br/novocadastro/plano=plano1"
                        style={{
                          backgroundColor: "#542d78",
                          color: "#fff",
                          border: 0,
                        }}
                        onClick={() => handleClickOpen(obj.id)}
                        target="_blank"
                        rel="noreferrer noopener">
                        Comprar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {open && <Payment open={open} handleClose={handleClose} planObjId={planObjId} />}
            </Grid>
          ))}
      </PlanCards>
    </>
  );
};

export default Plan;
