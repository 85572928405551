import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/styles";
import { makeStyles } from "@mui/styles";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import SelectMenu from "./SelectMenu";
import { filterPaymentNote, getPaymentTaskNames, getTransactionOffices } from "../../helper/API/paymentTaskByAdvocate";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBD7F4 !important",
      borderRadius: "6px !important",
    },
    "& .MuiOutlinedInput-notchedOutline > legend > span": {
      color: "#333333 !important",
      fontWeight: 600,
    },
    // "& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiDialog-paper .MuiDialog-paperWidthSm MuiDialog-paperFullWidth css-tlc64q-MuiPaper-root-MuiDialog-paper"
  },
  halfWidth: {
    width: "97% !important",
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "100%",
    padding: "10px 26px 10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      border: "none",
      // borderColor: '#80bdff',
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const DropdownFilter = ({ open, handleClose, setLoading, setTableRows }) => {
  const classes = useStyles();
  const [office, setOffice] = useState([]);
  const [userType, setUserType] = useState([]);
  const [defaultUserType, setDefaultUserType] = useState();
  const [formValues, setFormValues] = useState({
    fromDate: "",
    toDate: "",
    minAmount: "",
    maxAmount: "",
    userType: "",
    office: "",
    name: "",
    transaction: "",
  });
  useEffect(() => {
    getTransactionOffices().then(res => {
      // console.log("office res", res);
      if (res.success) {
        setOffice(res.data);
        // console.log("res data", res.data[0].id);
      } else {
        toast.error(res.message);
      }
    });

    getPaymentTaskNames().then(res => {
      // console.log("setUserType res", res);
      if (res.success) {
        setUserType(res.data);
      } else {
        // toast.error(res.message);
      }
    });
  }, []);
  // useEffect(() => {

  // }, []);

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleRadioChange = e => {
    // console.log("e.target.value,", e.target.value);
  };

  // console.log("formValues", formValues);
  const onFilterPaymnetNote = () => {
    // console.log("defaultUserType", defaultUserType);
    setLoading(true);
    const submitData = { filter: { ...formValues, minAmount: Number(formValues.minAmount), maxAmount: Number(formValues.maxAmount) } };
    // console.log("submitData", submitData);
    filterPaymentNote(submitData).then(res => {
      // console.log("res filter api", res);
      if (res.success) {
        setLoading(false);
        setTableRows(res.data);
        handleClose();
      } else {
        setLoading(false);

        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth>
        <DialogContent sx={{ maxWidth: "900px !important" }}>
          <DialogContentText id="alert-dialog-description" sx={{ maxWidth: "900px !important" }}>
            <Box className={classes.root}>
              <Grid container>
                <Grid item lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Responsável"
                    variant="outlined"
                    fullWidth
                    name="name"
                    placeholder="Digite o nome do responsável"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                  <TextField
                    label="Valor mínimo"
                    id="outlined-start-adornment"
                    name="minAmount"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} sx={{ mt: 3 }}>
                  <TextField
                    label="Valor máximo"
                    id="outlined-start-adornment"
                    name="maxAmount"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>data inicial</FormLabel>
                    <OutlinedInput
                      // value={formValues.date}
                      id="date"
                      name="fromDate"
                      type="date"
                      placeholder="Encontro"
                      aria-describedby="outlined-weight-helper-text"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} sx={{ mt: 3 }}>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>data final</FormLabel>
                    <OutlinedInput
                      // value={formValues.date}
                      id="date"
                      name="toDate"
                      type="date"
                      placeholder="Encontro"
                      aria-describedby="outlined-weight-helper-text"
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                  <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="office"
                      defaultValue={formValues.office}
                      onChange={handleChange}
                      input={<BootstrapInput />}>
                      <option aria-label="None" value="" disabled>
                        Selecione o usuário
                      </option>
                      {office?.map((obj, indx) => (
                        <option value={obj.id}>
                          <span>{obj.label}</span>
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item md={6} sx={{ mt: 3, pr: 2 }}>
                  <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="userType"
                      defaultValue={formValues.userType}
                      onChange={handleChange}
                      input={<BootstrapInput />}>
                      <option aria-label="None" value="" disabled>
                        Selecione o escritório
                      </option>
                      {userType?.map((obj, indx) => (
                        <option value={obj.id}>
                          <span>{obj.label}</span>
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item md={12} sx={{ mt: 3 }}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="todas"
                      name="transaction"
                      onChange={handleChange}>
                      <FormControlLabel value="all" control={<Radio />} label="Todas" />
                      <FormControlLabel value="income" control={<Radio />} label="Entradas" />
                      <FormControlLabel value="expense" control={<Radio />} label="Despesas" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 4, padding: "0 20px" }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={onFilterPaymnetNote}
            autoFocus
            sx={{ "&:hover": { backgroundColor: "#73469980" }, background: "#734699", color: "#fff" }}>
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DropdownFilter;
