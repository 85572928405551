import React from "react";
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/styles";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const AdvocateCard = ({ classes, handleAdvocate, singleProcessData, permission }) => {
  // console.log("singleProcessData", singleProcessData);
  let advocateNames = [];
  singleProcessData?.advocates?.map(obj => {
    if (obj?.name) {
      return advocateNames.push(obj.name);
    }
  });
  // console.log("singleProcessData.....", advocateNames.join());
  return (
    <div>
      <Card
        variant="elevation"
        className={classes.card}
        sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
        <CardContent sx={{ position: "relative" }}>
          <Box>
            <Typography sx={{ fontWeight: 600 }}>Advogados:</Typography>
            {/* <Typography sx={{ fontWeight: 500, color: "#77838F" }}>11:45 am - 16:30 pm</Typography> */}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "", position: "relative" }}>
              <Typography sx={{ display: "inline" }}>
                {/* {singleProcessData?.advocates?.map((obj, i) => (
                  <>
                    {obj?.name}
                    {i == singleProcessData?.advocates?.length - 1 ? "" : ","}{" "}
                  </>
                ))} */}
                {advocateNames
                  ?.filter(obj => obj != null)
                  ?.map((obj, i) => {
                    return (
                      <>
                        <span key={i}>{obj}</span>
                        <br />
                      </>
                    );
                  })}
              </Typography>

              <AvatarGroup max={4} sx={{ display: "flex", justifyContent: "start" }}>
                {singleProcessData?.advocates?.map((obj, i) => (
                  <Avatar src={obj?.avatar ? obj?.avatar : "assets/temp/default-profile.png"} alt="Profile" />
                ))}

                {/* <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
              </AvatarGroup>
            </Box>
            {permission?.isOwner && permission?.processMgt && (
              <Box onClick={handleAdvocate}>
                <Img
                  alt="Delete"
                  src="/assets/images/lawyers.png"
                  width={50}
                  height={50}
                  sx={{
                    maxWidth: "30px !important",
                    maxHeight: "30px !important",
                    posotion: "absolute",
                    background: "#fff",
                    color: "red",
                    padding: "12px",
                    borderRadius: "13px",
                  }}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdvocateCard;
