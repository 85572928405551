import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/styles";
import { Card, CardContent, Grid, TextField, Typography, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "3rem",
    // maxWidth: 500,
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,
      //   bottom: "4px",
      //   left: "6px !important",
      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
  },
  actionContainer: {
    background: "#D6D9FF",
    borderTopRightRadius: "13px",
    borderBottomRightRadius: "13px",
    // padding: "8px 15px",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "26px",
  },
  subtitle: {
    color: "#757575",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 18,
    margin: "0 !important",
  },
  card: {
    marginTop: "2rem",
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  opinion: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "22px",
  },
});

const AndamentosCard = ({ gaitsData, setResponseStr, hideShowInput, handleResponseChange, showId, onSubmitResponse, onEnterResponse }) => {
  const classes = useStyles();
  // const elem = useRef("");
  // console.log("elem.current.value", elem.current.value);

  // useEffect(() => {
  //   setResponseStr(elem.current.value);

  // }, [elem.current.value])

  return (
    <div>
      <Card
        variant="elevation"
        className={classes.card}
        sx={{ border: "1.3px solid rgba(117, 117, 117, 0.3)", marginBottom: "1rem", borderRadius: "13px" }}>
        <CardContent>
          <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={""}>
            <Box sx={{ backgroundColor: "#fff", border: "2px solid #fff", p: 2, borderRadius: "50%", height: "30px" }}>
              <a href={gaitsData?.urlDocumento} target="_blank" rel="noreferrer">
                <Img
                  alt="profile"
                  src="/assets/images/ragister.png"
                  sx={{
                    maxWidth: "60px !important",
                    maxHeight: "60px !important",
                  }}
                />
              </a>
            </Box>
            <Box flex={1} ml={1}>
              {
                gaitsData.textoAndamento ? (
                  <Typography sx={{ marginBottom: "1rem" }}>
                    <span className={classes.subtitle}>{gaitsData.textoAndamento}</span>
                  </Typography>
                ) : null
              }
              {gaitsData?.response
                ? gaitsData?.response?.map((obj, i) => (
                  <Typography sx={{ marginBottom: "1rem", ml: 5 }}>
                    <span style={{ fontWeight: 600 }}>{obj.name} </span>
                    <br />
                    <span> {obj.response}</span>
                  </Typography>
                ))
                : ""}

              <Typography sx={{ marginBottom: "1rem" }}>
                {showId === gaitsData.codAndamento && (
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <TextField
                      id="standard-basic"
                      label="Responder"
                      variant="standard"
                      name="response"
                      fullWidth
                      // inputRef={elem}
                      // defaultValue={elem.current.value}
                      sx={{ color: "#0085FF", marginLeft: "1rem" }}
                      onChange={e => handleResponseChange(e, gaitsData)}
                      onKeyPress={e => onEnterResponse(e, gaitsData)}
                    />
                    <InputAdornment position="end" sx={{ cursor: "pointer", mt: 5 }} onClick={() => onSubmitResponse(gaitsData)}>
                      <SendIcon />
                    </InputAdornment>
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <Img
                    alt="profile"
                    src="/assets/images/opinion.png"
                    sx={{
                      maxWidth: "60px !important",
                      maxHeight: "60px !important",
                    }}
                  />
                  <Typography
                    sx={{ marginLeft: "0.5rem", fontWeight: 700, color: " #0085FF", cursor: "pointer" }}
                    className={classes.opinion}
                    onClick={() => hideShowInput(gaitsData.codAndamento)}>
                    Parecer para o cliente
                  </Typography>
                </div>
              </Typography>
            </Box>
            <Box p={0} sx={{ display: "flex", justifyContent: "end" }}>
              {/* <Typography sx={{ fontWeight: 400, color: " #757575" }}>{gaitsData?.date}</Typography> */}
              <a
                href={gaitsData?.urlDocumento}
                download={gaitsData?.urlDocumento}
                target="_blank"
                style={{ fontWeight: 400, color: " #757575", px: 2 }} rel="noreferrer">
                <CloudDownloadIcon />
              </a>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default AndamentosCard;
