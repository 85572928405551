import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useLocation, useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import ScrollToBottom from "react-scroll-to-bottom";
import TimeStampCard from "./Gaits/TimeStampCard";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../config";
import { connect } from "react-redux";
import ChatDescriptionCard from "./ChatDescription/ChatDescriptionCard";
import { addChatAttachment, getChatAttachment, getPersonalChatHistory } from "../helper/API/chat";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ChatAttachment from "./ChatDescription/ChatAttachment";
import { toast } from "react-toastify";
import ErrorPage from "../pages/ErrorPage";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    marginBottom: "2rem",
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiTabs-indicator": {
      height: "100% !important",
      borderRadius: 160,

      background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
    },
    "& .MuiGrid-root": {
      "& .MuiPaper-root": {
        "& .MuiCardContent-root": {
          padding: "0 0 5rem 0 !important",
        },
      },
      "& .MuiContainer-root": {
        padding: 5,
      },
    },
  },
  yourBtn: {
    // position: "relative",
    // top: "150px",
    // width: "150px",
    padding: "5px",
    // border: "1px dashed #BBB",
    textAlign: "center",
    // backgroundColor: "#DDD",
    cursor: "pointer",
    marginLeft: "10px",
  },
  imgPreview: {
    borderRadius: "50%",
    height: "70px",
    width: "70px",
  },
});

const ChatDescription = ({ loginUser, permission }) => {
  const hiddenFileInput = React.useRef(null);
  const classes = useStyles();
  const id = loginUser?._id;
  const name = loginUser.name;
  const avatar = loginUser.avatar;
  const senderId = localStorage.getItem("senderId");
  const params = useParams();
  // console.log("params", params);

  const [chatOpen, setChatOpen] = useState(false);
  const [displayFileUpload, setDisplayFileUpload] = useState(false);
  const [value, setValue] = useState(0);
  const [chatHistory, setChatHistory] = useState([]);
  const [fileName, setFileName] = React.useState("");
  const [attachmentList, setAttachmentList] = React.useState([]);
  const [message, setMessage] = useState({
    sender: id,
    to: senderId,
    from: name,
    avatar: avatar,
    senderType: "advocate",
    message: "",
    newDate: moment(new Date()).format("DD-MM-YYYY"),
    type: "message",
  });
  const [attachment, setAttachment] = useState({
    sender: id,
    to: senderId,
    from: name,
    avatar: avatar,
    type: "attachment",
    message: "",
    newDate: moment(new Date()).format("DD-MM-YYYY"),
  });

  useEffect(() => {
    const submitData = { to: senderId };
    var socket = io(SOCKET_URL, { transports: ["websocket"] }); //CONNECTION ESTABLISHED
    socket.emit("user-connected", id);

    getPersonalChatHistory(submitData).then(res => {
      if (res.success) {
        setChatHistory(res.data);
        // console.log("res get chat history", res);
      }
      //  else {
      //   toast.error(res.message);
      // }
    });
  }, []);
  useEffect(() => {
    const submitData = { to: senderId };
    getChatAttachment(submitData).then(res => {
      if (res.success) {
        setAttachmentList(res.data);
        // console.log("res get chat attachment", res);
      } else {
        toast.error(res.message);
      }
    });
  }, []);

  useEffect(() => {
    var socket = io(SOCKET_URL, { transports: ["websocket"] }); //CONNECTION ESTABLISHED
    socket.emit("user-connected", id);
    socket.on("res-message", msg => {
      // console.log("data on event ON ENTER ::", msg);
      setChatHistory([...chatHistory, msg]); //FOR STORING MESSAGE
      // console.log("chatHistory", chatHistory);

      socket.emit("seen-message", msg);
    });
  }, [chatHistory]);

  // FOR ATTACHMENT UPLOAD
  const handleClickImage = event => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = event => {
    setDisplayFileUpload(true);
    const fileUploaded = event.target.files[0];
    if (event.target.files[0]) {
      setFileName(event.target.files[0].name);
      setAttachment({
        sender: id,
        to: senderId,
        from: name,
        avatar: avatar,
        type: "attachment",
        message: event.target.files[0],
        newDate: moment(new Date()).format("DD-MM-YYYY"),
        fileName: event.target.files[0].name,
      });

      const formData = new FormData();
      formData.append("sender", id);
      formData.append("to", senderId);
      formData.append("from", name);
      formData.append("avatar", avatar);
      formData.append("type", "attachment");
      formData.append("message", event.target.files[0]);
      formData.append("newDate", moment(new Date()).format("DD-MM-YYYY"));
      formData.append("fileName", event.target.files[0].name);

      // ADD THIS INTO API SUCCESS RESPONSE
      addChatAttachment(formData).then(res => {
        // console.log("attachment response ::", res);
        if (res.success) {
          setDisplayFileUpload(false);

          const message = {
            sender: id,
            to: senderId,
            from: name,
            avatar: avatar,
            type: "attachment",
            message: res.data.message,
            newDate: moment(new Date()).format("DD-MM-YYYY"),
            fileName: event.target.files[0].name,
          };

          var socket = io(SOCKET_URL, { transports: ["websocket"] });
          socket.emit("message", message);

          setChatHistory([...chatHistory, message]);
          setAttachment({
            sender: id,
            to: senderId,
            from: name,
            avatar: avatar,
            type: "attachment",
            message: "",
            newDate: moment(new Date()).format("DD-MM-YYYY"),
            fileName: "",
          });
          setDisplayFileUpload(false);
        } else {
          setDisplayFileUpload(false);
          toast.error(res.message);
        }
      });
    }
    // console.log("fileUploaded", fileUploaded);
  };
  // console.log("attachment", attachment);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChatChange = e => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitMessage = () => {
    if (message.message !== "" && message.message.trim()) {
      var socket = io(SOCKET_URL, { transports: ["websocket"] });
      socket.emit("message", message);
      setChatHistory([...chatHistory, message]);
    }
    setMessage({
      sender: id,
      to: senderId,
      from: name,
      avatar: avatar,
      senderType: "advocate",
      message: "",
      newDate: moment(new Date()).format("DD-MM-YYYY"),
      type: "message",
    });
  };

  const onEnter = e => {
    if (e.key === "Enter" && message.message.trim()) {
      var socket = io(SOCKET_URL, { transports: ["websocket"] });
      socket.emit("message", message);
      setChatHistory([...chatHistory, message]);
      setMessage({
        sender: id,
        to: senderId,
        from: name,
        avatar: avatar,
        senderType: "advocate",
        message: "",
        newDate: moment(new Date()).format("DD-MM-YYYY"),
        type: "message",
      });
    }
  };
  // console.log("chatHistory", chatHistory);

  return (
    <div>
      {params.id && permission?.chat ? (
        <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={{ xs: 0, sm: 0, md: 8 }} py={{ xs: 0, sm: 0, md: 4 }}>
          <Paper square className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="icon label tabs example">
              <Tab label="Chat" />
              <Tab label="Arquivos" />
            </Tabs>
          </Paper>

          {value === 0 ? (
            <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
              <Grid item xs={12} sm={12} md={12}>
                <Card variant="elevation" sx={{ px: 3 }}>
                  <ScrollToBottom>
                    <CardContent sx={{ height: "52vh" }}>
                      <div>
                        {chatHistory.map((obj, index) => (
                          <>
                            <TimeStampCard time={obj.newDate} />
                            <Grid sx={{ marginTop: "0.5rem" }}>
                              <div
                                style={{
                                  border: "1.47059px solid rgba(117, 117, 117, 0.3)",
                                  padding: "0.3rem 1rem",
                                  borderRadius: "14.7059px",
                                  color: "#636C84",
                                }}>
                                {/* {obj?.data?.map((item, index) => ( */}
                                <ChatDescriptionCard chatHistoryData={obj} />
                                {/* ))} */}
                              </div>
                            </Grid>
                          </>
                        ))}
                      </div>
                    </CardContent>
                  </ScrollToBottom>
                  <Container sx={{ padding: 0 }}>
                    <Grid sx={{ marginTop: "0.5rem" }}>
                      {displayFileUpload && <Typography sx={{ textAlign: "right" }}>{fileName} Enviando....</Typography>}
                      {chatOpen && (
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            sx={{ backgroundColor: "#ffffff" }}
                            id="outlined-adornment-weight1"
                            // defaultValue={message.message}
                            value={message.message}
                            name="message"
                            placeholder="Digite aqui..."
                            onChange={handleChatChange}
                            onKeyPress={onEnter}
                            autoFocus
                            endAdornment={
                              <>
                                <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                                  {/* <AttachFileIcon /> */}
                                  <div className={classes.yourBtn} onClick={handleClickImage}>
                                    <AttachFileIcon />
                                  </div>

                                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                                    <input
                                      id="upfile"
                                      type="file"
                                      accept=""
                                      ref={hiddenFileInput}
                                      onChange={handleImageChange}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                </InputAdornment>
                                <InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={onSubmitMessage}>
                                  <SendIcon />
                                </InputAdornment>
                              </>
                            }
                          />
                        </FormControl>
                      )}
                      {}
                      {!chatOpen && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <Button variant="contained" onClick={() => setChatOpen(!chatOpen)}>
                            Iniciar conversa
                          </Button>
                        </div>
                      )}
                    </Grid>
                  </Container>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <ChatAttachment senderId={senderId} setAttachmentList={setAttachmentList} attachmentList={attachmentList} />
          )}
        </Box>
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};

const mapStateToProp = state => {
  // console.log("state", state);
  return {
    loginUser: state?.loginUsers?.loginUser?.user,
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProp)(ChatDescription);
