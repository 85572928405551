import React, { useEffect } from "react";
import { Typography, Box, Grid, CardContent, Card, Divider, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomProgressBar from "../components/Inputs/ProgressBar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SimpleTable from "../components/SimpleTable";
import styled from "@emotion/styled";
import DadosModal from "../components/Inputs/DadosModal";
import { getPaymentHistory, getPlanPaymentDetail } from "../helper/API/dashboard";
import Loader from "../components/Loader";
import RecordFound from "../components/RecordFound/RecordFound";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  card: {
    minHeight: 250,
  },
  head: {
    fontSize: 25,
    fontWeight: 500,
  },
  desc: {
    fontSize: 19,
    fontWeight: 400,
    color: "#000000",
    opacity: 0.5,
  },
  cardHead: {
    fontSize: 22,
    fontWeight: 400,
  },
  descCard: {
    fontSize: 12.5,
    fontWeight: 400,
    color: "#000000",
    opacity: 0.5,
  },
  span: {
    marginLeft: 8,
    color: "#7F56DA",
    fontSize: 13,
    fontWeight: 500,
    background: "#F8F5FE",
    borderRadius: "83.9286px",
    padding: 7,
  },
  tag: {
    fontSize: "33px !important",
    fontWeight: "400 !important",
  },

  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  content1: {
    border: "0.9px solid #EEEFF3",
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    borderRadius: 5,
  },
});

const Img = styled("img")({
  display: "block",
  maxWidth: "50px",
  maxHeight: "50px",
});

const DadosAssinatura = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [cardData, setCardData] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    setTableLoading(true);
    getPlanPaymentDetail().then(res => {
      // console.log("res", res);
      if (res.success) {
        setLoading(false);
        setCardData(res.data);
      } else {
        setLoading(false);
        toast.error(res.message);
      }
    });
    getPaymentHistory().then(res => {
      // console.log("res setTableData", res);
      if (res.success) {
        setTableLoading(false);
        setTableData(res.data);
      } else {
        setTableLoading(false);
        setTableData([]);
        // toast.error(res.message);
      }
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const progressBarData = {
    lightColor: "#F8F5FE",
    darkColor: "#7F56DA",
    value: Math.round((100 / cardData?.planDays) * cardData?.subDiffDays),
  };

  // console.log("tableData", tableData);

  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
      <Box>
        <Typography className={classes.head}>Cobrança</Typography>
        <Typography className={classes.desc}> Gerencie seus detalhes de cobrança e pagamento</Typography>
      </Box>
      <>
        {loading ? (
          <Loader />
        ) : cardData ? (
          <Box my={5}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={6} md={6}>
                <Typography variant="body1" className={classes.cardHead} mb={1}>
                  Frequência de cobrança
                </Typography>
                <Card variant="elevation" className={classes.card}>
                  <CardContent>
                    <div className={classes.content}>
                      <div>
                        <Typography variant="body1" className={classes.title}>
                          Plano {cardData?.planName} <span className={classes.span}>mensal</span>
                        </Typography>
                        <Typography variant="body1" className={classes.descCard}>
                          Plano mais popular
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.tag}>
                          R${cardData?.planPrice} <span className={classes.desc}>/ Mês</span>
                        </Typography>
                      </div>
                    </div>
                    <Typography variant="body1" className={classes.title} py={2}>
                      Seu plano (dias): {cardData?.subDiffDays} / {cardData?.planDays}
                    </Typography>
                    <CustomProgressBar data={progressBarData} />
                    <Box py={3}>
                      <Divider />
                    </Box>
                    {/* <Button
                  sx={{ paddingBottom: 2, float: "right", fontSize: 14, fontWeight: 600, textTransform: "lowercase" }}
                  onClick={handleClickOpen}>
                  Cancelar plano
                </Button> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2} sm={6} md={6}>
                <Typography variant="body1" className={classes.cardHead} mb={1}>
                  Método de pagamento
                </Typography>
                <Card variant="elevation" className={classes.card}>
                  <CardContent>
                    <Typography variant="body1" className={classes.title}>
                      Forma de pagamento
                    </Typography>
                    {/* <Typography variant="body1" className={classes.descCard}>
                      Altere sua forma de pagamento
                    </Typography> */}
                    <Box className={classes.content1} my={4}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!cardData?.type === "Trial Plan" && (
                          <div>
                            <Img
                              alt="complex"
                              src={cardData?.type === "Apple Pay" ? "/assets/images/applepay.png" : "/assets/images/gpay.png"}
                              sx={{ maxHeight: "50px", maxWidth: "50px" }}
                            />
                          </div>
                        )}{" "}
                        {/* <div>
                          <Typography variant="body1" className={classes.title}>
                            5634 **** **** 2135
                          </Typography>
                          <Typography variant="body1" className={classes.descCard}>
                            08/2024
                          </Typography>
                          <Typography variant="body1" className={classes.descCard} style={{ display: "flex" }}>
                            <MailOutlineIcon style={{ marginRight: 5 }} /> E-mail@gmail.com
                          </Typography>
                        </div>*}
                        
                      </div>
                      <div>
                        {/* <Button sx={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }} variant="outlined">
                          Editar
                        </Button> */}
                        <div style={{ margin: "0px 15px" }}>
                          <Typography variant="body1" className={classes.title}>
                            {cardData?.type === "Trial Plan" ? "Plano de teste" : cardData?.type}
                          </Typography>
                          <Typography variant="body1" className={classes.descCard}>
                            {cardData?.tempDate}
                          </Typography>
                          {!cardData?.type === "Trial Plan" && (
                            <Typography variant="body1" className={classes.descCard} style={{ display: "flex" }}>
                              ID da transação: {cardData?.transactionId}
                            </Typography>
                          )}
                          <Typography variant="body1" className={classes.descCard} style={{ display: "flex" }}>
                            <MailOutlineIcon style={{ marginRight: 5 }} /> {cardData?.email}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <RecordFound label="Nenhuma notificação encontrada" />
        )}
        {tableLoading ? (
          <Loader />
        ) : tableData.length ? (
          <Box my={8}>
            <Typography variant="body1" className={classes.cardHead} mb={1}>
              Faturas
            </Typography>
            <SimpleTable tableData={tableData} />
          </Box>
        ) : (
          <Box sx={{ mt: 3 }} px={8}>
            {/* <Card variant="elevation" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <CardContent sx={{ backgroundColor: "#fff", borderRadius: "20px" }}> */}
            <Box p={0} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <h4>{`Plano expira em ${cardData?.planDays - cardData?.subDiffDays} Dias.`}</h4>
            </Box>
            {/* </CardContent>
            </Card> */}
          </Box>
        )}
      </>

      <DadosModal open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} />
    </Box>
  );
};

export default DadosAssinatura;
