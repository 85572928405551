import React from "react";
import useStyles from "../../asset/css/clients";
import { styled } from "@mui/styles";
import { Link } from "react-router-dom";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { clientes } from "./data";
const Img = styled("img")({
  display: "block",
});
const DespesasCard = ({ bottomCards, selectVal }) => {
  const classes = useStyles();
  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };
  return (
    <div className={classes.root}>
      {bottomCards.map(obj => (
        <Card variant="elevation" className={classes.card} key={obj.id}>
          <CardContent>
            <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Box p={1}>
                {/* <Link to={`/home/cliente/${obj.id}`} key={obj.id}> */}
                <Img
                  alt="profile"
                  src="/assets/images/82.png"
                  sx={{
                    maxWidth: "30px !important",
                    maxHeight: "30px !important",
                    background: selectVal === "expense" ? "#8001FF !important" : "#FA9124 !important",
                    borderRadius: "50% !important",
                    padding: "10px !important",
                  }}
                />
                {/* </Link> */}
              </Box>
              <Box flex={1} p={1}>
                <p className={classes.title} p={0}>
                  {obj.name}
                </p>
                <p className={classes.subtitle}>{obj.date}</p>
              </Box>
              <Box p={0}>
                <Typography variant="h6" className={classes.despesasCard}>
                  {convertBrazilNumber(obj.amount)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default DespesasCard;
