import React, { useState, useEffect } from "react";
import { Paper, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { deleteDashboardCards, getDashboardCards } from "../../helper/API/dashboard";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  mainContainer: {
    display: "flex !important",
  },
  relative: {
    position: "relative",
    // marginRight: 40,
  },
  card: {
    // marginTop: 28,
    marginX: 15,

    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
    margin: "25px !important",
  },
  card1: {
    marginX: 15,
    background: "linear-gradient(149deg, #E96047 -19.6%, #EB57A6 44.21%, #4A3260 108.02%)",
    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
    margin: "25px !important",
  },
  card2: {
    marginX: 15,
    background: "linear-gradient(158deg,#F4A8A8 0%, #F4738C 91%, rgba(244, 115, 140, 0.7) 100%)",
    borderRadius: "17.4px !important",
    // minWidth: "350px !important",
    minHeight: 150,
    position: "relative",
    boxShadow: "0px 8.71526px 17.4305px rgba(0, 0, 0, 0.32) !important",
    margin: "25px !important",
  },
  title: {
    color: "#000",
    marginLeft: 20,
    fontSize: 18,
    fontWeight: 700,
  },
  desc: {
    margin: "15px 5px !important",
    color: "#fff",
  },
  arrow: {
    position: "absolute",
    borderRadius: "50%",
    top: "40%",
    right: -15,
    padding: "5px",
  },
});

const CarousalCard = ({ setCardLoading }) => {
  const [dashboardCards, setDashboardCards] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const classes = useStyles();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: "block", background: "#00000050", borderRadius: "50%" }} onClick={onClick} />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: "block", background: "#00000050", borderRadius: "50%" }} onClick={onClick} />
    );
  }

  const settings = {
    className: dashboardCards.length ? "center height-slider" : "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: dashboardCards?.length <= 2 ? 1 : 3,
    slidesToScroll: 3,
    speed: 200,
    height: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    getDashboardCards().then(res => {
      // console.log("res in get carousal cards", res);
      if (res.success) {
        setDashboardCards(res.data);
        if (!res.data.length) {
          setCardLoading(true);
        } else {
          setCardLoading(false);
        }
      } else {
        // toast.error(res.message);
      }
    });
  }, [refresh]);

  const deleteCard = id => {
    // console.log("delete card", id);
    const submitData = { id };
    deleteDashboardCards(submitData).then(res => {
      // console.log("delete", res);
      if (res.success) {
        toast.success(res.message);
        setRefresh(refresh + 1);
      } else {
        toast.error(res.message);
      }
    });
  };

  // console.log("dashboardCards", dashboardCards);

  return (
    <div>
      <Slider {...settings}>
        {dashboardCards?.map((obj, index) => (
          <div style={{ margin: 10 }}>
            <Card
              variant="elevation"
              className={classes.card}
              sx={{
                background:
                  index % 2
                    ? "linear-gradient(149deg, #E96047 -19.6%, #EB57A6 44.21%, #4A3260 108.02%)"
                    : "linear-gradient(194deg, #9882F6 48%, #0F153B 104%)",
                color: "#fff !important",
                height: "250px",
              }}>
              <CardContent>
                <Typography variant="body1" className={classes.title}>
                  <div style={{ display: "flex", justifyContent: "space-between", color: "#fff" }}>
                    <div>{obj.title}</div>
                    <div>
                      <CancelIcon onClick={() => deleteCard(obj._id)} sx={{ cursor: "pointer", color: "#ffffff" }} />
                    </div>
                  </div>
                </Typography>
                <Typography variant="body2" className={classes.desc}>
                  {obj.message}
                </Typography>
                <Typography variant="body2" className={classes.desc}>
                  Assunto: <br /> {obj.subtitle}
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
        {/* <div style={{ margin: 10 }}>
          <Card variant="elevation" className={classes.card1}>
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div> Mensagem Equipe:</div>
                  <div>
                    <CancelIcon />
                  </div>
                </div>
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ margin: 10 }}>
          <Card variant="elevation" className={classes.card2}>
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                Mensagem Cliente:
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
              </Typography>
            </CardContent>
          </Card>
        </div> */}
        {/* <div style={{ margin: 10 }}>
          <Card variant="elevation" className={classes.card2}>
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                Mensagem Cliente:
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ margin: 10 }}>
          <Card variant="elevation" className={classes.card2}>
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                Mensagem Cliente:
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ margin: 10 }}>
          <Card variant="elevation" className={classes.card2}>
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                Mensagem Cliente:
              </Typography>
              <Typography variant="body2" className={classes.desc}>
                Adriana: Bom dia dr. Fernando! Qual a estimativa para o andamento do processo?
              </Typography>
            </CardContent>
          </Card>
        </div> */}
      </Slider>
    </div>
  );
};

export default CarousalCard;
