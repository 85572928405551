import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, NativeSelect, Button, Input, FormControl, TextField, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import FinanceSelect from "../components/FinanceiroAdicionar/FinanceSelect";
import { getPaymentTaskNames, getTransactionType, getTransactionOffices, addPaymentNote } from "../helper/API/paymentTaskByAdvocate";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const useStyles = makeStyles({
  headLabel: {
    fontWeight: 500,
    fontSize: 26,
    color: "#1C152A",
  },
  amount: {
    color: "#747BE6",
    fontWeight: 700,
    fontSize: 26,
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
  dropdownStyle: {
    border: "none !important",
    borderBottom: "1px solid black",
    borderRadius: "5%",
  },
});

const FinanceiroAdicionar = ({ permission }) => {
  const classes = useStyles();

  const [transactionType, setTransactionType] = useState([]);
  const [name, setName] = useState([]);
  const [office, setOffice] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [labels, setLabels] = useState("");
  const params = useLocation();
  const navigate = useNavigate();
  // console.log("params.state", params.state.transaction);
  const [selectState, setSelectState] = useState({
    transaction: params.state.transaction,
    transactionType: "",
    userType: "",
    office: "",
    frequency: 1,
    period: "",
    amount: "",
    name: "",
  });

  useEffect(() => {
    if (permission?.isOwner && permission?.financialMgt) {
      const submitData = { type: params.state.transaction };
      getTransactionType(submitData).then(res => {
        if (res.success) {
          setTransactionType(res.data);
        }
      });
      getPaymentTaskNames().then(res => {
        if (res.success) {
          setName(res.data);
        }
      });
      getTransactionOffices().then(res => {
        if (res.success) {
          setOffice(res.data);
        }
      });
    }
  }, [refresh]);

  // console.log("labels", labels);
  const onSelectChange = e => {
    setSelectState({
      ...selectState,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    const submitData = { ...selectState, transactionType: selectState.transactionType.id };
    // console.log("submitData", submitData);
    addPaymentNote(submitData).then(res => {
      // console.log("add note res", res);
      if (res.success) {
        toast.success(res.message);
        navigate("/home/financeiro");
      } else {
        toast.error(res.message);
      }
    });
  };
  // console.log("office", office);
  return (
    <Box sx={{ background: "#F8F5FA" }} px={10} py={4}>
      <Box my={6}>
        <Typography className={classes.headLabel} sx={{ color: "#5031A9" }}>
          {/* Lançar nota: <span className={classes.amount}>R$250.00</span> */}
          <Typography onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
            Voltar
          </Typography>
        </Typography>
      </Box>
      {/* <Box sx={{ display: "flex", alignItems: "center", color: "#5031A9", justifyContent: "start", marginBottom: "2rem" }}>
                    <Typography >Voltar</Typography>

                </Box> */}
      <Box my={7}>
        <FinanceSelect
          transactionType={transactionType}
          name={name}
          office={office}
          refresh={refresh}
          setRefresh={setRefresh}
          onSelectChange={onSelectChange}
          selectState={selectState}
          setLabels={setLabels}
        />
      </Box>
      <Box textAlign={"center"}>
        <Button
          sx={{
            background: "linear-gradient(44.42deg, #FF543E 8.04%, #C837AB 92.29%)",
            fontSize: 17,
            fontWeight: 600,
            textTransform: "capitalize",
            borderRadius: 40,
            minWidth: "30%",
          }}
          onClick={handleSubmit}
          variant="contained">
          Adicionar
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(FinanceiroAdicionar);
