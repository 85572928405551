import React, { useState } from "react";
import { Avatar, AvatarGroup, Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import DropdownButton from "../components/Processos/DropdownButton";
import { useLocation, useNavigate } from "react-router-dom";
import AdvocateModal from "../components/ProcessDetailModals/AdvocateModal";
import AuthorModal from "../components/ProcessDetailModals/AuthorModal";
import DefendantModal from "../components/ProcessDetailModals/DefendantModal";
import { getAdvocate } from "../helper/API/advocate";
import { getClient } from "../helper/API/cliente";
import ProcessDetailCard from "../components/ProcessDetailModals/ProcessDetailCard";
import AdvocateCard from "../components/ProcessDetailModals/AdvocateCard";
import AuthorCard from "../components/ProcessDetailModals/AuthorCard";
import DefendantCard from "../components/ProcessDetailModals/DefendantCard";
import { changeProcessStatus, getProcessStatus, getSingleProcess, processCourtCost } from "../helper/API/process";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { InputAdornment } from "@mui/material";
import Loader from "../components/Loader";

const Img = styled("img")({
  display: "block",
  //   maxWidth: "40%",
  //   maxHeight: "100%",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

let authorData = ["ab", "bc", "cd", "de"];

const ProcessoDetail = ({ permission }) => {
  const params = useLocation();
  const TaskDetail = params?.state?.taskList;
  window.localStorage.setItem("process", JSON.stringify(TaskDetail));
  const classes = useStyles();
  const navigate = useNavigate();

  const [openAdvocate, setOpenAdvocate] = React.useState(false);
  const [openAuthor, setOpenAuthor] = React.useState(false);
  const [openDefendant, setOpenDefendant] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modalData, setModalData] = React.useState([]);
  const [singleProcessData, setSingleProcessData] = React.useState([]);
  const [statusProcess, setStatusProcess] = React.useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectVal, setSelectVal] = useState();
  const [dropDownValue, setDropDownValue] = useState([]);
  const [statusId, setStatusId] = useState(TaskDetail ? TaskDetail?.processStatus?.id : "");
  const [cost, setCost] = useState(TaskDetail.cost ? TaskDetail.cost : "");
  const [laoding, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  // console.log("TaskDetail", TaskDetail);
  const [tableRows, setTableRows] = useState([]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    setLoading(true);
    const submitData = { id: TaskDetail.id };
    getAdvocate().then(res => {
      // console.log("res get advocate", res);
      if (res.success) {
        setData(res.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
    getClient().then(res => {
      // console.log("res get client", res);
      if (res.success) {
        setModalData(res.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
    getSingleProcess(submitData).then(res => {
      if (res.success) {
        setSingleProcessData(res.data);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
    getProcessStatus().then(res => {
      // console.log("statusProcess res", res);
      if (res.success) {
        setStatusProcess(res.data);
        setSelectVal(res.data[0].label);
        setStatusId(res.data[0].id);
      } else {
        toast.error(res.message);
      }
    });
  }, []);
  // React.useEffect(() => {
  //   const submitData = { id: TaskDetail.id, status: statusId };
  //   changeProcessStatus(submitData).then(res => {
  //     // console.log("res change status", res);
  //     if (res.success) {
  //       toast.success(res.message);
  //     }
  //   });
  // }, [statusId]);

  const handleGaits = () => {
    navigate("/home/gaits", { state: { TaskDetail } });
  };

  const handleAdvocate = () => {
    setOpenAdvocate(true);
  };
  const handleAuthor = () => {
    setOpenAuthor(true);
  };
  const handleDefendant = () => {
    setOpenDefendant(true);
  };
  const [val, setVal] = React.useState(TaskDetail?.processStatus ? TaskDetail?.processStatus?.id : "");

  const handleSelectChange = event => {
    setVal(event.target.value);

    const submitData = { id: TaskDetail.id, status: event.target.value };
    changeProcessStatus(submitData).then(res => {
      // console.log("res change status", res);
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  // console.log("TaskDetail", TaskDetail);
  // console.log("val", val);
  // console.log("cost", cost);

  const onBlurCost = () => {
    const submitData = {
      id: TaskDetail.id,
      cost: cost.split(".").join("").split(",").join("."),
    };
    // console.log("submitData", submitData);
    processCourtCost(submitData).then(res => {
      // console.log("res cost", res);
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR");
  };
  // console.log("singleProcessData", singleProcessData);
  return (
    <div>
      <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        {laoding ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "end", marginBottom: "2rem" }}>
              <DropdownButton
                // open={open}
                // handleClick={handleClick}
                // handleClose={() => setAnchorEl(null)}
                // setAnchorEl={setAnchorEl}
                // anchorEl={anchorEl}
                // setSelectVal={setSelectVal}
                dropDownValue={dropDownValue}
                // handleSelectChange={handleSelectChange}
                // selectVal={selectVal}
                statusProcess={statusProcess}
                // setStatusId={setStatusId}
                value={val}
                handleChange={handleSelectChange}
                items={[
                  { key: "Last 7 Days", value: 7 },
                  { key: "Last 28 Days", value: 28 },
                  { key: "Last 90 Days", value: 90 },
                ]}
              />
            </Box>
            {/* <Box sx={{ display: "inline-block", paddingX: 2, backgroundColor: "#fff", position: "relative", left: "1%", top: "8%" }}>
          <Typography sx={{ fontWeight: 600 }}>Custas processuais</Typography>
        </Box> */}
            {/* <Card
              variant="elevation"
              className={classes.card}
              sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
              <CardContent sx={{ position: "relative" }}>
                <Box>
                  <TextField
                    id="outlined-basic"
                    label="Custas processuais"
                    name="cost"
                    variant="outlined"
                    fullWidth
                    defaultValue={convertBrazilNumber(cost)}
                    // value={convertBrazilNumber(cost)}
                    placeholder="150"
                    onChange={e => setCost(e.target.value)}
                    onBlur={onBlurCost}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    // startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    // onChange={handleChange}
                  />
                </Box>
              </CardContent>
            </Card> */}
            <Card
              variant="elevation"
              className={classes.card}
              sx={{ background: "#fff", marginBottom: "1rem", borderRadius: "13px", paddingX: 2 }}>
              <CardContent sx={{ position: "relative" }}>
                <Box>
                  <Typography sx={{ fontWeight: 600 }}>
                    Título: <span style={{ fontWeight: "normal" }}>{singleProcessData?.titulo}</span>{" "}
                  </Typography>
                  {/* <Typography>{singleProcessData?.numProcesso}</Typography> */}
                </Box>
              </CardContent>
            </Card>
            <AdvocateCard classes={classes} permission={permission} handleAdvocate={handleAdvocate} singleProcessData={singleProcessData} />
            <AuthorCard
              classes={classes}
              permission={permission}
              handleAuthor={handleAuthor}
              data={authorData}
              singleProcessData={singleProcessData}
            />
            <DefendantCard
              classes={classes}
              permission={permission}
              handleDefendant={handleDefendant}
              singleProcessData={singleProcessData}
            />
            <ProcessDetailCard classes={classes} singleProcessData={singleProcessData} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#5031A9",
                  width: "30%",
                  fontWeight: 700,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  lineHeight: "35px",
                  letterSpacing: "1.49px",
                  textTransform: "capitalize",
                }}
                onClick={handleGaits}>
                Andamentos
              </Button>
            </Box>
          </>
        )}
      </Box>
      {openAdvocate && (
        <AdvocateModal
          singleProcessData={singleProcessData}
          TaskDetail={TaskDetail}
          open={openAdvocate}
          data={data}
          handleClose={() => setOpenAdvocate(false)}
          setSingleProcessData={setSingleProcessData}
        />
      )}
      {openAuthor && (
        <AuthorModal
          authorData={authorData}
          TaskDetail={TaskDetail}
          open={openAuthor}
          data={modalData}
          handleClose={() => setOpenAuthor(false)}
          singleProcessData={singleProcessData}
          setSingleProcessData={setSingleProcessData}
        />
      )}
      {openDefendant && (
        <DefendantModal
          singleProcessData={singleProcessData}
          TaskDetail={TaskDetail}
          open={openDefendant}
          data={modalData}
          handleClose={() => setOpenDefendant(false)}
          setSingleProcessData={setSingleProcessData}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProps)(ProcessoDetail);
