import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CircularProgress from "@mui/material/CircularProgress";
import { uploadProcess } from "../../helper/API/process";
import { toast } from "react-toastify";

const Img = styled("img")({
  display: "block",
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiButtonBase-root": {
      background: "red",
    },
  },

  card: {
    // marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: "14px",
  },
});

const StyledButton = styled(Button)`
  &:hover {
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
`;

const ProcessosHeader = ({
  handleOpen,
  refresh,
  setRefresh,
  formValues,
  subscribed,
  totalLimit,
  isSearchResult,
  setProcessModal,
  setProcessModalData,
  permission,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const hiddenFileInput = React.useRef(null);

  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "") return false;
    }
    return true;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    hiddenFileInput.current.click();
  };
  // console.log("subscribed", subscribed);

  const handleImageChange = event => {
    setLoading(true);
    // console.log("event.target.files[0]", event.target.files[0]);
    if (event.target.files[0]) {
      const submitData = { xmlFile: event.target.files[0] };
      uploadProcess(submitData).then(res => {
        // console.log("res", res);
        if (res.success) {
          setProcessModalData(res.data);
          setLoading(false);
          setProcessModal(true);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    }
  };

  // console.log("checkProperties(formValues);", checkProperties(formValues));
  return (
    <div>
      <Box className={classes.card} py={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"}>
          <Box p={1} sx={{ display: "flex", alignItems: "center" }}>
            {!checkProperties(formValues) && (
              <ClearAllIcon onClick={() => setRefresh(refresh + 1)} sx={{ mx: 1, p: 1, borderRadius: "50%", border: "2px solid black" }} />
            )}
            {/* <DropdownFilter btnText={"Filtro"} imgSrc={"/assets/images/filter.png"} /> */}
          </Box>
          {/* <Box p={1}>
            <StyledButton
              variant="contained"
              // disableElevation
              onClick={handleOpen}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                borderRadius: "18px",
                diplay: "flex",
                justifyContent: "space-around",
                backgroundColor: "#fff",
                color: "#000",
                width: "100%",
                height: "100%",
                py: 2,
              }}>
              <Img
                alt="profile"
                src="/assets/images/calander.png"
                sx={{
                  maxWidth: "60px !important",
                  maxHeight: "60px !important",
                  paddingX: "1rem",
                }}
              />
              Hoje
            </StyledButton>
          </Box> */}
        </Box>
        <Box p={0}>
          {permission?.isOwner && permission?.processMgt && (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.actionContainer}>
              <Img
                alt="profile"
                src="/assets/images/uploadFile.png"
                sx={{
                  maxWidth: "20px !important",
                  maxHeight: "20px !important",
                  paddingX: "1rem",
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <Img src={imagePreview ? imagePreview : "/assets/images/uploadImg.png"} className={classes.imgPreview} /> */}
                  <div className={classes.yourBtn} onClick={handleClick}>
                    <Typography sx={{ color: "#734699", display: "flex", alignItems: "center" }}>
                      Carregar/Baixar - xls
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            // color: "green",
                            marginLeft: "1rem",
                          }}
                        />
                      )}
                    </Typography>
                  </div>

                  <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
                    <input
                      id="upfile"
                      type="file"
                      accept={".xlsx, .xls"}
                      ref={hiddenFileInput}
                      disabled={loading}
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className={classes.card}
        py={1}
        sx={{ fontWeight: 600, fontSize: "1.2rem" }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}>
        <Box p={1}>
          {/* <DropdownFilter btnText={"Filtro"} imgSrc={"/assets/images/filter.png"} /> */}
          Processos cadastrados:
        </Box>

        <Box p={0}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.actionContainer}>
            <Typography>
              ({subscribed}) {isSearchResult ? "" : <>Limite: {totalLimit}</>}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ProcessosHeader;
