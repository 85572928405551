import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { gapi } from "gapi-script";
import { verifyProviderId } from "../helper/API/auth";
import { toast } from "react-toastify";
import * as loginAction from "../redux/login/action";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";
import { appleProvider, authentication, provider } from "../firebase";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, CLIENT_ID } from "../config";
import { LoginSocialApple } from "reactjs-social-login";

import { AppleLoginButton } from "react-social-login-buttons";

import AppleLogin from "react-apple-login";

import * as loginPlanResponse from "../redux/storeLoginResponse/action";
import { shouldRedirectToPlan } from "../helper/validation/userPlan";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const LoginPage = ({ loginData, loginProcessing, setLoginPlanResponse }) => {
  const { login, loginSocialAuth } = useAuth();
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);

  // const clientId = "619960625495-g5nqfoj2t59oi5rclphdcl5euc13icqh.apps.googleusercontent.com";
  const clientId = "680552504511-1k98moqnnir7lbkf25ck36pu0a11ip1g.apps.googleusercontent.com";

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });
  const navigate = useNavigate();
  const onSuccess = resp => {
    const submitData = { providerId: resp.googleId };
    // console.log("resp", resp);
    if (resp.googleId) {
      // console.log("success:", resp);
      verifyProviderId(submitData).then(res => {
        // console.log("data in check provider id", res);
        // console.log("data in check provider idsssss", { data: res.data });
        if (res.success) {
          setLoginPlanResponse(res);
          loginData(res.data);
          if (shouldRedirectToPlan(res.data.user)) {
            navigate("/plan", { replace: true });
          } else {
            navigate("/home", { replace: true });
            loginData(res.data);
            toast.success(res.message);
            loginSocialAuth(res);
          }
        } else {
          toast.error("E-mail não cadastrado.");
        }
      });
    } else {
      // console.log("failed:", err);
    }
  };
  const onFailure = err => {
    // console.log("failed:", err);
    toast.error(err);
  };

  // FACEBOOK LOGIN
  const handleFacebookLogin = e => {
    e.preventDefault();
    signInWithPopup(authentication, provider)
      .then(resp => {
        // console.log("res facebook login", resp);

        if (resp.user.uid) {
          const submitData = { providerId: resp.user.uid };

          verifyProviderId(submitData).then(res => {
            // console.log("res", res);
            if (res.success) {
              loginData(res.data);
              setLoginPlanResponse(res);
              if (shouldRedirectToPlan(res.data.user)) {
                navigate("/plan", { replace: true });
              } else {
                navigate("/home", { replace: true });
                loginData(res.data);
                toast.success(res.message);
                loginSocialAuth(res);
              }
            } else {
              toast.error("E-mail não cadastrado.");
            }
          });
        } else {
          toast.error("E-mail não cadastrado");
        }
      })
      .catch(error => {
        // Error; SMS not sent
        // console.log("error facebook login", error);
      });
  };
  // APPLE LOGIN
  const onSuccessApple = (provider, data) => {
    // console.log("res ::: ", provider);
    // console.log("data", data);

    // e.preventDefault();

    if (provider.data.authorization.id_token) {
      const submitData = { providerId: provider.data.authorization.id_token };

      verifyProviderId(submitData).then(res => {
        // console.log("res", res);
        if (res.success) {
          setLoginPlanResponse(res);
          loginData(res.data);
          if (shouldRedirectToPlan(res.data.user)) {
            navigate("/plan", { replace: true });
          } else {
            navigate("/home", { replace: true });
            loginData(res.data);
            toast.success(res.message);
            loginSocialAuth(res);
          }
        } else {
          toast.error("E-mail não cadastrado.");
        }
      });
    } else {
      toast.error("E-mail não cadastrado");
    }
  };

  const handleSubmit = event => {
    console.log("clicked");
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const submitData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    localStorage.setItem("email", data.get("email"));
    localStorage.setItem("password", data.get("password"));
    // console.log("submitData", submitData);
    login(submitData);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 5, backgroundColor: "#fff" }}>
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Img alt="complex" src="/assets/images/inova_juris_logo.png" sx={{ height: 150 }} />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {/* <form></form> */}
          <TextField
            margin="normal"
            placeholder="Digite seu E-mail "
            required
            fullWidth
            id="email"
            label="Endereço de E-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            placeholder="Digite sua senha"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Grid container sx={{ marginBottom: "0.5rem" }}>
            <Grid item md={6}>
              <RouterLink to="/register">
                <Link variant="body2" sx={{ color: "#9A9AB0", textDecoration: "none" }}>
                  Não tem uma conta? Registro
                </Link>
              </RouterLink>
            </Grid>
            <Grid item md={6} textAlign={"end"}>
              <RouterLink to="/forgot-password">
                <Link variant="body2" sx={{ color: "#9A9AB0", textDecoration: "none" }}>
                  {t("forgotPassword")}
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
          <Button
            type={"submit"}
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2, backgroundColor: "#734699" }}
            disabled={loginProcessing}>
            Entrar
            {loginProcessing && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapDispatchToProp = dispatch => {
  return {
    loginData: data => dispatch(loginAction.setLoginUser(data)),
    setLoginPlanResponse: data => dispatch(loginPlanResponse.setLoginResponse(data)),
  };
};
const mapStateToProps = state => {
  return {
    loginProcessing: state?.loginUsers?.processing,
  };
};

export default connect(mapStateToProps, mapDispatchToProp)(LoginPage);
