import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/styles";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { addClient, addNote, getNoteType } from "../../helper/API/cliente";
import { toast } from "react-toastify";

const Img = styled("img")({
  display: "block",
});
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiFormControlLabel-label": {
      fontWeight: "700 !important",
    },
    "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#ffff",
      zIndex: 60,
    },
    "& .MuiCardContent-root": {
      padding: "2rem !important",
    },
    "& .MuiTextField-root": {
      margin: "11px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "unset !important",
      borderRadius: "19px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DBD7F4 !important",
    },
    "& .MuiOutlinedInput-notchedOutline > legend > span": {
      color: "#DBD7F4 !important",
    },
    "$ .MuiInputBase-root": {
      color: "#2C3143 !important",
    },
  },
  card: {
    marginBottom: "1rem",
    boxShadow: "unset !important",
    borderRadius: 11,
  },
  CardContent: {
    paddingBottom: "0 !important",
  },
  total: {
    fontFamily: "Poppins",
    color: "#747B96",
    fontWeight: 500,
  },
  amount: {
    color: "#747BE6 !important",
    fontWeight: "700 !important",
  },
  mainTitle: {
    fontSize: 25,
    fontWeight: 400,
    margin: "2rem 0",
    color: "#5031A9",
    cursor: "pointer",
  },
  title: {
    color: "#0A0615",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
    margin: "0 !important",
  },
  subtitle: {
    color: "#404B52",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 14,
    margin: "0 !important",
  },
});

const Cliente = () => {
  let params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const client = location?.state?.clientInfo;
  const [screen, setScreen] = useState(0);
  const [fetchData, setFetchData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [info, setInfo] = useState({
    id: client ? client.id : "",
    name: client ? client.name : "",
    email: client ? client.email : "",
    companyType: client ? client.companyType : "",
    telephone: client ? client.telephone : "",
    CPF: client ? client.CPF : "",
    CEP: client ? client.CEP : "",
    address: client ? client.address : "",
    neighborhood: fetchData?.bairro ? fetchData?.bairro : client ? client.neighborhood : "",
    city: client ? client?.city : "",
    CNPJ: client ? client?.CNPJ : "",
    // password: client ? client.password : "",
  });
  // console.log("info", info);
  // console.log("fetchData", fetchData);
  const [noteType, setNoteType] = useState([]);

  const [note, setNote] = useState({
    value: "",
    desc: "",
    client: client?.id,
    noteType: "",
  });

  //get note type
  useEffect(() => {
    getNoteType().then(res => {
      if (res.success) {
        setNoteType(res.data);
      } else {
        toast.error(res.message);
      }
    });
  }, []);

  //client detail
  const handleChange = e => {
    // console.log("e.target.name", e.target.name);
    // console.log("e.target.value", e.target.value.replace("-", ""));
    if ((e.target.name === "CEP" && e.target.value.length === 8) || e.target.value.length === 9) {
      fetch(`https://viacep.com.br/ws/${e.target.value.replace("-", "")}/json/`)
        .then(response => response.json())
        .then(data => {
          setFetchData(data);
          setInfo({
            ...info,
            CEP: e.target.value,
            address: client ? client.neighborhood : data?.logradouro ? data?.logradouro : "",
            neighborhood: client ? client.neighborhood : data?.bairro ? data?.bairro : "",
            city: client ? client?.city : data?.localidade ? data?.localidade : "",
          });
          // console.log("setFetchData(data)", data);
        });
    }
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  //note fields
  const handleChangeNote = e => {
    setNote({
      ...note,
      [e.target.name]: e.target.value,
    });
  };

  //submit Advocate
  const handleSaveCliente = () => {
    setLoading(true);
    const submitData = {
      name: info.name,
      email: info.email,
      companyType: info.companyType,
      telephone: info.telephone,
      ...(info.companyType === "Legal" && { CNPJ: info.CNPJ }),
      ...(info.companyType === "Physical" && { CPF: info.CPF }),

      CEP: info.CEP,
      address: info.address,
      neighborhood: info.neighborhood,
      city: info.city,
    };
    // console.log("submitData", submitData);
    addClient(submitData).then(res => {
      if (res.success) {
        // console.log("res", res);
        toast.success(res.message);
        navigate("/home/clients");
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
  };

  //submit note
  const handleSaveNote = () => {
    const submitData = { ...note, value: Number(note.value), client: client?.id };
    // console.log("note", note);
    // console.log("submitData", submitData);
    addNote(submitData).then(res => {
      if (res.success) {
        toast.success(res.message);
        navigate("/home/clients");
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
    <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
      <div className={classes.root}>
        {screen === 0 ? (
          <>
            {!params?.id ? (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className={classes.mainTitle} onClick={() => navigate(-1)}>
                  Voltar
                </div>
                <Button disabled={loading} variant="contained" onClick={handleSaveCliente}>
                  Salvar
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        marginLeft: "12px",
                      }}
                    />
                  )}
                </Button>
              </div>
            ) : (
              <div className={classes.mainTitle} onClick={() => navigate(-1)}>
                Voltar
              </div>
            )}

            <Card variant="elevation" className={classes.card}>
              <CardContent>
                <TextField
                  name="name"
                  fullWidth
                  defaultValue={client && client?.name}
                  InputLabelProps={{
                    style: { color: "#747BE6" },
                  }}
                  onChange={handleChange}
                  placeholder="Nome"
                />
                <TextField
                  fullWidth
                  defaultValue={client ? client?.email : ""}
                  type="email"
                  name="email"
                  InputLabelProps={{
                    style: { color: "#747BE6" },
                  }}
                  onChange={handleChange}
                  placeholder="E-mail"
                />
                <FormControl sx={{ m: 1 }} fullWidth>
                  <Select
                    defaultValue={client ? client.companyType : ""}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    name="companyType"
                    inputProps={{ "aria-label": "Without label" }}>
                    <MenuItem value="" disabled>
                      <em>Selecione o tipo de Pessoa Física ou Jurídica</em>
                    </MenuItem>
                    <MenuItem value="Physical">Fisica</MenuItem>
                    <MenuItem value="Legal">Jurídica</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <TextField
                    defaultValue={client ? client?.telephone : ""}
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    name="telephone"
                    sx={{ width: "50%" }}
                    onChange={handleChange}
                    placeholder="Telefone"
                  />
                  {info.companyType === "Legal" && (
                    <TextField
                      defaultValue={client ? client?.CNPJ : ""}
                      InputLabelProps={{
                        style: { color: "#747BE6" },
                      }}
                      name="CNPJ"
                      sx={{ width: "50%" }}
                      onChange={handleChange}
                      placeholder="CNPJ"
                    />
                  )}
                  {info.companyType === "Physical" && (
                    <TextField
                      defaultValue={client ? client?.CPF : ""}
                      InputLabelProps={{
                        style: { color: "#747BE6" },
                      }}
                      name="CPF"
                      sx={{ width: "50%" }}
                      onChange={handleChange}
                      placeholder="CPF"
                    />
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <TextField
                    defaultValue={client ? client.CEP : ""}
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    name="CEP"
                    sx={{ width: "50%" }}
                    onChange={handleChange}
                    placeholder="CEP"
                  />
                  <TextField
                    defaultValue={info.address}
                    value={info.address}
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    name="address"
                    sx={{ width: "50%" }}
                    onChange={handleChange}
                    placeholder="Endereço"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <TextField
                    // defaultValue={client ? client.neighborhood : fetchData ? fetchData?.bairro : ""}
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    name="neighborhood"
                    sx={{ width: "50%" }}
                    onChange={e => handleChange(e)}
                    placeholder="Vizinhança"
                    defaultValue={info.neighborhood}
                    value={info.neighborhood}
                  />
                  <TextField
                    defaultValue={info.city}
                    value={info.city}
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    CircleIcon
                    name="city"
                    sx={{ width: "50%" }}
                    onChange={handleChange}
                    placeholder="Cidade"
                  />
                </div>
                {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}> */}

                {/* <TextField
                  defaultValue={client ? client.companyType : ""}
                  fullWidth
                  InputLabelProps={{
                    style: { color: "#747BE6" },
                  }}
                  onChange={handleChange}
                  placeholder="Company Type"
                /> */}
                {/* {!client && (
                  <TextField
                    defaultValue={client ? client.password : ""}
                    fullWidth
                    InputLabelProps={{
                      style: { color: "#747BE6" },
                    }}
                    name="password"
                    onChange={handleChange}
                    placeholder="Password"
                  />
                )} */}
                {/* </div> */}
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} m={2}>
                  <Typography variant="h5" noWrap className={classes.total}>
                    Total de notas lançadas
                  </Typography>
                  <Typography variant="h5" noWrap className={`${classes.total} ${classes.amount}`}>
                    R${client ? ` ${client?.amount}` : " 0"}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            {params?.id && params?.id !== "null" ? (
              <div
                style={{
                  display: "flex",
                  position: "realtive",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "1.5%",
                  cursor: "pointer",
                }}
                onClick={() => setScreen(1)}>
                <Img
                  alt="profile"
                  src="/assets/images/wallet.png"
                  sx={{
                    maxWidth: "85px !important",
                    maxHeight: "85px !important",
                    position: "absolute",
                    left: "3%",
                    zIndex: 99,
                  }}
                />
                <Card sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", marginLeft: "2%" }}>
                  <CardContent sx={{ marginLeft: "2%" }}>
                    <Typography variant="h5" noWrap className={classes.total}>
                      Lançar nota
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ) : (
              ""
            )}
          </>
        ) : params?.id !== null ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div className={classes.mainTitle} onClick={() => navigate(-1)}>
                {" "}
                Voltar{" "}
              </div>
              <Button variant="contained" onClick={handleSaveNote}>
                Salvar
              </Button>
            </div>
            <Card variant="elevation" className={classes.card}>
              <CardContent>
                <Box flex={1} p={1}>
                  <p className={classes.title} p={0}>
                    {client?.name}
                  </p>
                  <p className={classes.subtitle}>{client?.email}</p>
                </Box>
                <div style={{ marginTop: "1%" }}>
                  <Typography variant="h6" noWrap className={classes.total} mx={1}>
                    Valor da nota
                  </Typography>
                  <TextField name="value" fullWidth placeholder="R$150,00" onChange={handleChangeNote} />
                  <TextField
                    name="desc"
                    fullWidth
                    placeholder="Descrição do lançamento"
                    multiline
                    rows={4}
                    maxRows={4}
                    onChange={handleChangeNote}
                  />
                </div>
                <div style={{ marginTop: "1%" }}>
                  <Typography variant="h6" noWrap className={classes.total} m={1}>
                    Tipo de nota
                  </Typography>
                  <FormControl sx={{ m: 1 }} fullWidth>
                    <Select
                      name="noteType"
                      onChange={handleChangeNote}
                      displayEmpty
                      fullWidth
                      inputProps={{ "aria-label": "Without label" }}>
                      {/* <MenuItem value="" disabled>
                        <em>Select company type</em>
                      </MenuItem> */}
                      {noteType.map((obj, indx) => (
                        <MenuItem value={obj.id} key={indx}>
                          {obj.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </CardContent>
            </Card>
          </>
        ) : (
          ""
        )}
      </div>
    </Box>
  );
};

export default Cliente;
