import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { makeStyles } from "@mui/styles";
import Chats from "../components/Chats";
import { useNavigate } from "react-router-dom";
import { getChatDetail } from "../helper/API/chat";
import Loader from "../components/Loader";
import RecordFound from "../components/RecordFound/RecordFound";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../config";
import { connect } from "react-redux";
import ErrorPage from "./ErrorPage";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 160,
    // maxWidth: 500,
    "& .MuiGrid-root": {
      "& .MuiPaper-root": {
        "& .MuiCardContent-root": {
          padding: "0 0 5rem 0 !important",
        },
      },
      "& .MuiContainer-root": {
        padding: 5,
      },
    },
  },
});

const ChatPage = ({ loginUser, permission }) => {
  const id = loginUser?._id;
  const classes = useStyles();
  const navigate = useNavigate();

  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("loginUser", loginUser);
  useEffect(() => {
    if (permission?.chat) {
      setLoading(true);
      getChatDetail()
        .then(res => {
          // console.log("res chatlist", res);
          setLoading(false);
          if (res.success) {
            setChatList(res.data);
          }
        })
        .catch(err => setLoading(false));
    }
  }, []);

  useEffect(() => {
    // const submitData = { to: senderId };
    var socket = io(SOCKET_URL, { transports: ["websocket"] }); //CONNECTION ESTABLISHED
    socket.emit("user-connected", id);

    socket.on("res-message", (data, chatData) => {
      if (chatData?.data) {
        setChatList(chatData?.data);
      }
      // console.log("data on event ON ENTERRRR ::", chatData);
      // console.log("data on event ON data ::", data);
    });
  }, []);

  // console.log("chatList", chatList);

  const handelChats = id => {
    // console.log("clicked function");

    localStorage.setItem("senderId", id);
    navigate(`/home/chat-file/${id}`, { state: { SingleChatUserId: id } });
  };

  return (
    <div>
      {permission?.chat ? (
        <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
          <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>
            <Grid item xs={12} sm={12} md={12}>
              <Card variant="elevation" sx={{ cursor: "pointer" }}>
                <CardContent>
                  {loading ? (
                    <Loader />
                  ) : chatList.length ? (
                    chatList?.map((obj, index) => (
                      <>
                        <Chats
                          sx={{
                            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#B9BBBD30",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          chatData={obj}
                          onClick={handelChats}
                        />
                      </>
                    ))
                  ) : (
                    <RecordFound label="Nenhum registro foi encontrado" />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};

const mapStateToProp = state => {
  return {
    loginUser: state?.loginUsers?.loginUser?.user,
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProp)(ChatPage);
