import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { getDashboardNotification } from "../helper/API/dashboard";
import RecordFound from "./RecordFound/RecordFound";

const NotificationMenu = ({ open, handleClose, anchorEl, notificationData }) => {
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      // "& .MuiBox-root": {
      "& .MuiPaper-root": {
        // border: "5px solid red",
        backgroundColor: "#F8F5FA",
      },
      // },
      "& .MuiGrid-root": {
        "& .MuiFormControl-root": {
          "& .MuiFormLabel-root": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#333333",
          },
        },
      },
    },

    card: {
      // marginBottom: "1rem",
      boxShadow: "unset !important",
      // borderRadius: "14px",
      border: "none",
      borderRadius: "20px",
      backgroundColor: "#F8F5FA",
      display: "flex",
    },
    content: {
      borderRadius: "20px",
      flexGrow: 5,
    },
  });
  const classes = useStyles();
  const StyledMenu = styled(props => (
    <Menu
      elevation={0}
      // anchorOrigin={{
      //     vertical: 'bottom',
      //     horizontal: 'right',
      // }}
      // transformOrigin={{
      //     vertical: 'top',
      //     horizontal: 'right',
      // }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: 8,
      minWidth: 200,

      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenu-list": {
        // backgroundColor: "black",
        // color: "#fff"
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: 12,
        },
      },
    },
  }));

  const [notifications, setNotifications] = React.useState(notificationData);
  useEffect(() => {
    // getDashboardNotification().then(res => {
    //   console.log("res notification received", res);
    //   if (res.success) {
    setNotifications(notificationData);
    //   }
    // });
  }, [notificationData]);
  // console.log("notifications received", notifications);
  // console.log("notifications receive notificationData", notificationData);

  return (
    <div>
      <StyledMenu
        id="notification-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "notification-button",
        }}>
        <Container>
          <CardContent>
            <Grid container>
              {/* <Grid item md={1} >
                            </Grid> */}
              <Grid item md={9} sx={{ marginBottom: "3rem", marginTop: "1rem" }}>
                <Typography variant="h5" sx={{ fontWeight: "600", color: "#222B45" }}>
                  {" "}
                  Notificações
                </Typography>
              </Grid>
              <Grid item md={2} sx={{ marginTop: "1rem" }}>
                <div
                  style={{
                    // padding: "0.6rem 0.6rem",
                    // boxShadow: "0px 12.3427px 18.5141px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderRadius: "50%",
                    height: "25px",
                    // width: "25px",
                  }}>
                  {/* <img src="/assets/images/icon.png" height={15} /> */}
                </div>
              </Grid>
              {/* <Grid item md={2}></Grid>
              <Grid item md={5} sx={{ color: "#222222", fontSize: "18px", fontWeight: 600 }}>
                novas mensagens
              </Grid>
              <Grid
                item
                md={5}
                sx={{ color: "#AAAAAA", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                AGORA MESMO
              </Grid> */}
            </Grid>
            {notifications?.length ? (
              notifications?.map((obj, index) => (
                <Grid container>
                  <Grid item md={12}>
                    <Grid container spacing={2} mt={3}>
                      <Grid item md={2}>
                        <Grid container sx={{ padding: "1rem 1rem" }}>
                          <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
                            {obj?.mainType === "message" && (
                              <Box
                                sx={{
                                  padding: "1rem",
                                  borderRadius: "50%",
                                  background: "linear-gradient(175.65deg, #FE2D66 3.99%, #734699 97.46%)",
                                  boxShadow: "0px 9.95139px 14.9271px rgba(8, 42, 69, 0.15)",
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <img src="/assets/images/reviewsicon.png" />
                              </Box>
                            )}
                            {obj?.mainType === "finance" && (
                              <Box
                                sx={{
                                  padding: "1rem",
                                  borderRadius: "50%",
                                  background: "linear-gradient(176.74deg, #FE2D66 13.24%, #FF5A01 89.21%)",
                                  boxShadow: "0px 10.7288px 16.0933px rgba(8, 42, 69, 0.15)",
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <img src="/assets/images/finance-card.png" />
                              </Box>
                            )}
                            {obj?.mainType === "process" && (
                              <Box
                                sx={{
                                  padding: "1rem",
                                  borderRadius: "50%",
                                  background: "linear-gradient(175.65deg, #9882F6 3.99%, #0F153B 97.46%)",
                                  boxShadow: "0px 10.7288px 16.0933px rgba(8, 42, 69, 0.15)",
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <img src="/assets/images/process.png" />
                              </Box>
                            )}
                            {obj?.mainType === "other" ||
                              (obj?.mainType === "PLAN" && (
                                <Box
                                  sx={{
                                    padding: "1rem",
                                    borderRadius: "50%",
                                    background: "linear-gradient(117.83deg, #FFCBCB -6.27%, #ECA3B1 45.05%)",
                                    boxShadow: "0px 10.7288px 16.0933px rgba(8, 42, 69, 0.15)",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}>
                                  <img src="/assets/images/reviewsicon.png" />
                                </Box>
                              ))}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          md={12}
                          sx={{
                            height: "60%",
                            display: "flex",
                            justifyContent: "center",
                          }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "85%",
                              bgcolor: "#DDDDDD",
                            }}>
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{
                                border: "1px solid #DDDDDD",
                                width: "2px",
                                borderRadius: "2.98542px",
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item md={9}>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Typography
                              sx={{
                                mb: 1.5,
                                color: "#222222",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}>
                              {obj?.title}
                            </Typography>
                            <hr width={"100%"} />
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#222222",
                                fontSize: "18px",
                                fontWeight: 600,
                              }}>
                              {obj?.subtitle}
                            </Typography>
                            <Typography variant="body2" sx={{ width: "275px" }}>
                              {obj?.message}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              // <RecordFound label="Nenhuma notificação encontrada" />
              <Box sx={{ mt: 3 }} className={classes.root} px={1}>
                <Card variant="elevation" className={classes.card}>
                  <CardContent className={classes.content} sx={{ backgroundColor: "#fff", borderRadius: "20px" }}>
                    <Box p={0} display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                      <img src="/assets/images/artwork.png" />
                      <h3 style={{ color: "#888888" }}>Nenhuma notificação encontrada</h3>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
          </CardContent>
        </Container>
        {/* </Container> */}
      </StyledMenu>
    </div>
  );
};
export default NotificationMenu;
