import React from "react";
import { Link } from "react-router-dom";
import { Box, Card, CardContent, Container, FormControl, MenuItem, Select, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import HoverMenu from "./HoverMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DropDown from "./DropDown";

const useStyles = makeStyles({
  card: {
    background: "linear-gradient(249.14deg, #F7BE2D -19.09%, #F78E2D 104.43%)",
    boxShadow: " 0px 4.59259px 4.59259px rgba(0, 0, 0, 0.25) !important",
    borderRadius: "17.4px !important",
    minWidth: "200px !important",
    minHeight: 70,
    position: "relative",
    marginRight: 6,
  },
  card2: {
    background: "linear-gradient(249.14deg, #B961FE -19.09%, #26063E 104.43%)",
    boxShadow: "0px 4.59259px 4.59259px rgba(0, 0, 0, 0.25) !important",
    borderRadius: "17.4px !important",
    minWidth: "200px !important",
    minHeight: 70,
    position: "relative",
  },
  cardContent: {
    paddingBottom: "0 !important",
    padding: "12px 21px !important",
  },
  title: {
    color: "#fff",
    marginLeft: 20,
    fontSize: "16px !important",
    fontFamily: "Poppins !important",
  },
  desc: {
    fontSize: "20px !important",
    color: "#fff",
    fontWeight: "700  !important",
    fontFamily: "Poppins !important",
  },
});

const Cards = ({ dropDownValue, statusProcess, value, handleChange, cards, cardData }) => {
  const classes = useStyles();
  // console.log("cards", cards);
  // console.log("cardData", cardData);

  const convertBrazilNumber = x => {
    return Number(x)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "brl",
    });
  };
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
      <Box p={1} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {" "}
            <Typography className={classes.title}>Entradas</Typography>
            <Typography className={classes.desc}>{convertBrazilNumber(cardData?.income ? cardData?.income : 0)}</Typography>
          </CardContent>
        </Card>
        <Card className={classes.card2} ml={2}>
          <CardContent className={classes.cardContent}>
            {" "}
            <Typography className={classes.title}>Despesas</Typography>
            <Typography className={classes.desc}>{convertBrazilNumber(cardData?.expense ? cardData?.expense : 0)}</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box p={1}>
        <DropDown dropDownValue={dropDownValue} statusProcess={statusProcess} value={value} handleChange={handleChange} />
      </Box>
    </Box>
  );
};

export default Cards;
