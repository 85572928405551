import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles({
  root: {
    margin: "2% 0",
    borderRadius: "13px !important",
    display: "flex",
    alignItems: "center",
    width: "99%",
    padding: "6px 13px",
    boxShadow: "unset !important",
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "& .MuiButtonBase-root-MuiIconButton-root": {
      padding: "0 !important",
    },
    "& .MuiInputBase-root": {
      padding: "0 5px !important",
      borderRadius: "13px",
    },

    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#77838F",
      letterSpacing: "1px",
    },
  },
});

const SearchInput = ({ onChange, searchVal, onClick, placeholder }) => {
  const searchClasses = useStyles();

  return (
    <div>
      <Paper className={searchClasses.root}>
        <InputBase
          classes={{ root: searchClasses.customTextField }}
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          onChange={onChange}
          value={searchVal}
        />
        {searchVal && (
          <IconButton type="button" sx={{ padding: "0 !important", mr: "15px" }} aria-label="search" onClick={onClick}>
            <CancelIcon />
          </IconButton>
        )}
      </Paper>
    </div>
  );
};

export default SearchInput;
