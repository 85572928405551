import React, { Suspense, useEffect, useState } from "react";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { getAdvocate } from "../../helper/API/advocate";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteAdvocateModal from "./DeleteAdvocateModal";
import PlusBottom from "../PlusBottom";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import * as advocateAction from "../../redux/advocateList/action";
import { connect } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchInput from "../SearchInput";
import { toast } from "react-toastify";
import ErrorPage from "../../pages/ErrorPage";

const Img = styled("img")({
  display: "block",
});

const SearchImg = styled("img")({
  display: "block",
  maxWidth: "90px",
});
const useStyles = makeStyles({
  root: {
    margin: "2% 0",
    borderRadius: "13px !important",
    display: "flex",
    alignItems: "center",
    width: "99%",
    padding: "6px 13px",
    boxShadow: "unset !important",
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
    "& .MuiButtonBase-root-MuiIconButton-root": {
      padding: "0 !important",
    },
    "& .MuiInputBase-root": {
      padding: "0 5px !important",
      borderRadius: "13px",
    },

    "& .MuiPaper-elevation": {
      boxShadow: "unset !important",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#77838F",
      letterSpacing: "1px",
    },
  },
});

const AdvogadosList = ({ onAdvogadosCard, classes, advocateListData, advocateListFromRedux, processing, permission }) => {
  const searchClasses = useStyles();
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(processing);
  const [AdvocateList, setAdvocateList] = useState(advocateListFromRedux);
  const [deleteId, setDeleteId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // console.log("AdvocateList", AdvocateList);
  // console.log("processing", processing);
  useEffect(() => {
    if (permission?.isOwner && permission?.teamMgt) {
      setLoading(true);
      getAdvocate().then(res => {
        console.log("res ::getadvocate", res);
        if (res.success) {
          setAdvocateList(res.data);
          setLoading(false);
        } else {
          setAdvocateList([]);
          setLoading(false);
          toast.error(res.message);
        }
      });
      advocateListData();
    }
  }, [refresh]);

  const onSearchAdvocate = () => {
    setSearchVal("");
    setAdvocateList(advocateListFromRedux);
  };

  const handleEditAdvocate = obj => {
    // console.log("obj", obj);
    navigate(`/home/adicionar-advogado/${obj.id}`, { state: { id: obj.id, advocate: obj } });
  };
  const handleDeleteAdvocate = id => {
    setShow(true);
    setDeleteId(id);
  };
  const handleChatAdvocate = id => {
    // console.log("id personal advocate", id);
    localStorage.setItem("senderId", id);
    navigate(`/home/chat-file/${id}`);
  };

  const handleClick = () => {
    navigate("/home/adicionar-advogado");
  };
  const onChangeSearch = event => {
    setSearchVal(event.target.value);
    if (event.target.value) {
      var updateState = AdvocateList.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase()));
      setAdvocateList(updateState);
      updateState = [];
      // console.log("obj if 1");
    } else if (event.target.value == "") {
      setAdvocateList(advocateListFromRedux);
      // console.log("obj if 2");
    } else {
      setAdvocateList([]);
      // console.log("obj if 3");
    }
  };
  // console.log("searchVal", searchVal);

  return (
    <div>
      {/* <Paper className={searchClasses.root} component="form">
        <InputBase
          classes={{ root: searchClasses.customTextField }}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar cliente"
          inputProps={{ "aria-label": "Buscar cliente" }}
          onChange={onChangeSearch}
          value={searchVal}
        />
        {searchVal && (
          <IconButton type="button" sx={{ padding: "0 !important", mr: "15px" }} aria-label="search" onClick={onSearchAdvocate}>
            <CancelIcon />
          </IconButton>
        )}
      </Paper> */}
      {/* {permission?.isOwner && permission?.teamMgt ? ( */}
      <>
        <SearchInput placeholder={"Buscar advogado"} onChange={onChangeSearch} searchVal={searchVal} onClick={onSearchAdvocate} />
        <Divider />
        <PlusBottom url="/" handleClick={handleClick} />
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : !AdvocateList?.length ? (
          <Box sx={{ display: "flex", justifyContent: "center", height: "100%", color: "#7a7a7a" }}>
            <h2>Nenhum registro foi encontrado</h2>
          </Box>
        ) : (
          <div style={{ cursor: "pointer", marginTop: 35 }}>
            {AdvocateList?.map((obj, indx) => (
              <Box
                className={classes.root}
                p={2}
                mb={2}
                borderRadius={"8px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ backgroundColor: "#734699" }}
                key={indx}>
                <Box p={1} onClick={() => onAdvogadosCard(obj.id)}>
                  <Avatar alt="" src={obj.avatar} />
                </Box>
                <Box flex={1} onClick={() => onAdvogadosCard(obj.id)}>
                  <Typography className={classes.title} p={0}>
                    {obj.name}
                  </Typography>
                  {obj?.occupationArea && <Typography className={classes.subtitle}>{obj?.occupationArea?.label}</Typography>}
                </Box>

                <Box>
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    {/* <div
                    style={{
                      posotion: "relative",
                      padding: "5px 10px",
                      background: "#734699",
                      borderTopRightRadius: "13px",
                      borderBottomRightRadius: "13px",
                    }}>
                    <Img
                      alt="Delete"
                      src="/assets/images/delete.png"
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "30px !important",
                        maxHeight: "30px !important",
                        posotion: "absolute",
                        background: "#fff",
                        padding: "12px",
                        borderRadius: "13px",
                      }}
                    />
                  </div>

                  <div style={{ padding: "5px 15px" }}>
                    <Img
                      alt="Chat"
                      src="/assets/images/plus.png"
                      width={50}
                      height={50}
                      sx={{
                        background: "#734699",
                        padding: "12px 12px",
                        borderRadius: "13px",
                        maxWidth: "29px !important",
                        maxHeight: "29px !important",
                      }}
                    />
                  </div> */}

                    <ChatBubbleOutlineIcon
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "20px !important",
                        maxHeight: "20px !important",
                        posotion: "absolute",
                        background: "#fff",
                        color: "#734699",
                        padding: "12px",
                        borderRadius: "11px",
                        cursor: "pointer",
                        mr: 2,
                      }}
                      onClick={() => handleChatAdvocate(obj.id)}
                    />
                    <EditIcon
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "20px !important",
                        maxHeight: "20px !important",
                        posotion: "absolute",
                        background: "#fff",
                        color: "#734699",
                        padding: "12px",
                        borderRadius: "11px",
                        cursor: "pointer",
                        mr: 2,
                      }}
                      onClick={() => handleEditAdvocate(obj)}
                    />
                    <Img
                      alt="Delete"
                      src="/assets/images/registerDelete.png"
                      width={50}
                      height={50}
                      sx={{
                        maxWidth: "20px !important",
                        maxHeight: "20px !important",
                        posotion: "absolute",
                        background: "#fff",
                        padding: "12px",
                        borderRadius: "11px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteAdvocate(obj.id)}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
        )}
      </>
      {/* ) : (
        <ErrorPage />
      )} */}
      {show && (
        <DeleteAdvocateModal
          open={show}
          advocateListData={advocateListData}
          handleClose={() => setShow(false)}
          deleteId={deleteId}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
};
const mapDispatchToProp = dispatch => {
  return {
    advocateListData: () => dispatch(advocateAction.advocateList()),
  };
};
const mapStateToProp = state => {
  return {
    advocateListFromRedux: state?.advocateListReducer?.advocateList,
    processing: state?.advocateListReducer?.processing,
    permission: state?.getProfileReducer?.profile,
  };
};

export default connect(mapStateToProp, mapDispatchToProp)(AdvogadosList);
