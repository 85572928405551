import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Slider from "./Slider";

const useStyles = makeStyles({
  card: {
    // background: "linear-gradient(249.14deg, #F7BE2D -19.09%, #F78E2D 104.43%)",
    boxShadow: " 0px 4.59259px 4.59259px rgba(0, 0, 0, 0.25) !important",
    borderRadius: "17.4px !important",
    minWidth: "600px !important",
    minHeight: 70,
    position: "relative",
    marginRight: 6,
  },
  card2: {
    background: "linear-gradient(249.14deg, #B961FE -19.09%, #26063E 104.43%)",
    boxShadow: "0px 4.59259px 4.59259px rgba(0, 0, 0, 0.25) !important",
    borderRadius: "17.4px !important",
    minWidth: "200px !important",
    minHeight: 70,
    position: "relative",
  },
  cardContent: {
    paddingBottom: "0 !important",
    padding: "12px 21px !important",
  },
  title: {
    color: "#fff",
    marginLeft: 20,
    fontSize: "16px !important",
    fontFamily: "Poppins !important",
  },
  desc: {
    fontSize: "20px !important",
    color: "#fff",
    fontWeight: "700  !important",
    fontFamily: "Poppins !important",
  },
});
const WeekCalender = () => {
  const classes = useStyles();

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {" "}
          <Slider />
          {/* <Typography className={classes.title}>Entradas</Typography>
          <Typography className={classes.desc}>R$10,722.60</Typography> */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default WeekCalender;
