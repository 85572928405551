import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, CircularProgress, Grid } from "@mui/material";
import GooglePayButton from "@google-pay/button-react";
import { Elements, useStripe, CardElement, useElements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import CloseIcon from "@mui/icons-material/Close";
import { subscribePlan } from "../../helper/API/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { STRIPE_PUBLISHABLE_KEY } from "../../config";

const stripePromise = loadStripe(
  STRIPE_PUBLISHABLE_KEY,
  { locale: "pt-BR" },
);

const Payment = ({ open, handleClose, planObjId, loginUser, loginDataResponse }) => {
  const StripeButton = () => {
    const stripe = useStripe();
    const [pr, setPR] = useState(null);
    const [loading, setLoading] = useState(false);
    const Elements = useElements();
    const navigate = useNavigate();
    // useEffect(() => {
    //   if (!stripe || !Elements) {
    //     return;
    //   }
    //   const payment = stripe.paymentRequest({
    //     country: "US",
    //     currency: "usd",
    //     total: {
    //       label: "Demo total",
    //       amount: 1000,
    //     },
    //     requestPayerName: true,
    //     requestPayerEmail: true,
    //   });
    //   console.log(payment);
    //   payment
    //     .canMakePayment()
    //     .then(result => {
    //       if (result) {
    //         setPR(payment);
    //       }
    //     })
    //     .catch(err => {
    //       console.log("err : " + err);
    //     });
    // }, [stripe, Elements]);
    const { login, loginSocialAuth, newDataSet } = useAuth();

    const handleSubmit = async event => {
      event.preventDefault();
      setLoading(true);
      if (Elements == null) {
        setLoading(false);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: Elements.getElement(CardElement),
      });

      console.log('error : ', error);

      console.log(paymentMethod);

      if (paymentMethod.id) {
        let submitData = {
          token: paymentMethod.id,
          planId: planObjId,
        };
        // console.log("submitData", submitData);

        subscribePlan(submitData).then(res => {
          if (res.success) {
            newDataSet(loginUser);
            loginSocialAuth({ loginUser });
            navigate("/home");
            setLoading(false);
            toast.success(res.message);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        });
      }
    };
    return (
      <>
        <form
          onSubmit={handleSubmit}
          className="payment-form"
          style={{ display: "flex", flexDirection: "column", gap: "30px", padding: "20px 0px" }}>
          <CardElement />
          <div className="end">
            <button className="payment-button" type="submit" disabled={loading}>
              Pagar
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    marginLeft: "12px",
                  }}
                />
              )}
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }} id="alert-dialog-title">
          <span>Pagamentos</span>

          <button style={{ border: "0px", padding: "0px", background: "none", cursor: "pointer" }} onClick={handleClose}>
            <CloseIcon />
          </button>
        </DialogTitle>
        <DialogContent style={{ width: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            {/* <GooglePayButton
              environment="TEST"
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: "CARD",
                    parameters: {
                      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                      allowedCardNetworks: ["MASTERCARD", "VISA"],
                    },
                    tokenizationSpecification: {
                      type: "PAYMENT_GATEWAY",
                      parameters: {
                        gateway: "example",
                        gatewayMerchantId: "exampleGatewayMerchantId",
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: "12345678901234567890",
                  merchantName: "Demo Merchant",
                },
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPriceLabel: "Total",
                  totalPrice: "1",
                  currencyCode: "USD",
                  countryCode: "US",
                },
                shippingAddressRequired: true,
                callbackIntents: ["SHIPPING_ADDRESS", "PAYMENT_AUTHORIZATION"],
              }}
              onLoadPaymentData={paymentRequest => {
                console.log("Success", paymentRequest);
              }}
              onPaymentAuthorized={paymentData => {
                console.log("Payment Authorised Success", paymentData);
                return { transactionState: "SUCCESS" };
              }}
              onPaymentDataChanged={paymentData => {
                console.log("On Payment Data Changed", paymentData);
                return {};
              }}
              existingPaymentMethodRequired="false"
              buttonColor="black"
              buttonType="Buy"
            /> */}
            <hr />
            <Elements stripe={stripePromise}>
              <StripeButton />
            </Elements>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const mapStateToProp = state => {
  // console.log("state plan screen ::", state);
  return {
    loginUser: state?.loginUsers?.loginUser,
    loginDataResponse: state?.loginPlanResponseReducer?.data,
    isTrial: state?.loginUsers?.loginUser?.user?.isTrailUsed,
  };
};

export default connect(mapStateToProp)(Payment);
